.error-page {
  overflow: hidden;
  position: relative;

  svg.dec-1 {
    display: block;
    position: absolute;
    left: 0;
    bottom: -70px;
    z-index: 60;

    @include respond-down(M) {
      display: none;
    }
  }
  svg.dec-2 {
    display: block;
    position: absolute;
    right: 0;
    top: -130px;
    z-index: 60;

    @include respond-down(M) {
      display: none;
    }
  }

  .error-page-content {
    width: 100%;
    height: 100vh;
    background: url('/static/images/404-bg@2x.jpg') no-repeat center center;
    background-size: cover;

    .bg-filler {
      background: linear-gradient(to bottom, #9B7EB2 0%, rgba(154, 126, 177, 0.97) 14%, rgba(154, 126, 177, 0.84) 100%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 10;
    }


    .data {
      position: relative;
      z-index: 50;
      padding: 82px;
      height: 100vh;

      @include respond-down(L) {
        padding: 50px;
      }
      @include respond-down(S) {
        padding: 20px 0;
      }

      .container {
        margin-bottom: 200px;

        @include respond-down(L) {
          margin-bottom: 120px;
        }
        @include respond-down(S) {
          margin-bottom: 80px;
        }

        .go-to-main {
          font-size: 14px;
          font-weight: bold;
          color: #FFF;
          text-transform: uppercase;
          line-height: 1.24em;

          @include respond-down(L) {
            font-size: 12px;
          }

          svg {
            display: block;
            margin-bottom: 4px;
            transition: transform 0.25s ease;

            path {
              fill: #FFF;
            }
          }

          &:hover {
            svg {
              transform: translateX(-5px);
            }
          }
        }

        svg.round-logo {
          position: absolute;
          left: 50%;
          margin-left: -47px;
          width: 94px;
          top: 0;

          @include respond-down(XS) {
            width: 78px;
            height: 78px;
            margin: 0;
            left: auto;
            right: 0;
          }

          path {
            fill: #FFF;
          }
        }
      }

      .code-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .code {
          font-size: 150px;
          color: #FFF;
          line-height: 1.1em;

          @include respond-down(L) {
            font-size: 110px;
          }
          @include respond-down(S) {
            font-size: 90px;
          }
        }

        .helper {
          border-top: 1px solid rgba(255, 255, 255, 0.38);
          padding: 20px;
          color: #FFF;
          line-height: 1.4em;
          font-size: 16px;
          text-align: center;
          margin-bottom: 40px;

          @include respond-down(L) {
            font-size: 12px;
          }
        }

        .button {
          height: 52px;
          min-width: 220px;
          text-align: center;
          justify-content: center;

          @include respond-down(S) {
            height: 40px;
            min-width: 190px;
          }
        }
      }
    }

  }
}