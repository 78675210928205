.index-page {
  position: relative;

  .menu-phone-opener {
    background: $col-active;
  }

  .wrap-header-top{

    .menu-wrapper{

      &__top{
        border-color: $col-line;
      }
      .nav-top{
        .phone, .top-address{
          color: $white;
        }
      }
      .nav-items {
        .item{
          color: $white;

          &:hover, &.current{
            color: $col-active;
          }
        }

      }
    }

  }

  .body {
    // display: none;
    overflow: hidden;
    position: relative;

    .decorator-right {
      position: absolute;
      right: -280px;
      top: 1150px;

      @include respond-down(L) {
        width: 500px;
        top: 800px;
        right: -200px;
      }

      @include respond-down(M) {
        top: 930px;
      }

      @include respond-down(S) {
        display: none;
      }
    }

    .decorator-left {
      position: absolute;
      left: -370px;
      bottom: 900px;

      @include respond-down(L) {
        width: 500px;
        left: -200px;
        bottom: 750px;
      }

      @include respond-down(M) {
        width: 400px;
        left: -200px;
        bottom: 650px;
      }

      @include respond-down(S) {
        display: none;
      }
    }
  }

  .container-mini {
    margin-left: 75px;
    margin-right: 75px;

    @include respond-down(L) {
      margin-left: 60px;
      margin-right: 60px;
    }

    @include respond-down(M) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .main-menu {
    background: #9a79b4;

    .menu-wrapper {
      .logo-ny-w {
        display: none;
      }
      .logo-ny {
        display: block;
      }
    }

    .logo-href {
      position: relative;

      &:after {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        left: 50%;
        border-radius: 10px;
        top: 50%;
        box-shadow: 0 0 40px 60px rgba(255, 255, 255, 0.5);
        background: rgba(255, 255, 255, 0.5);
        opacity: 0.2;
        z-index: 2;

        @include respond-down(S) {
          width: 100px;
          left: 20px;
          box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.5);
        }
      }

      .logo, .logo-mobile {
        position: relative;
        z-index: 5;

        .figure {
          fill: #fff;
        }
      }
    }

    .right-wrapper {

      .nav-top {
        .top-address {
          color: #FFF;
        }
      }

      .nav-items {
        .item {
          color: rgba(255,255,255,0.65);

          &:hover {
            color: rgba(255,255,255,1);
          }
        }
      }

      .phone {
        color: #fff;
      }
    }
  }

  .index-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    height: 1010px;
    z-index: 10;
    background: #f1f1f1;
    overflow: hidden;

    @include respond-down(XL) {
      height: 760px;
    }

    @include respond-down(L) {
      height: 644px;
    }

    @include respond-down(M) {
      height: 594px;
      top: 0;
    }

    @include respond-down(S) {
      height: 700px;
    }

    @include respond(XS) {
      height: 600px;
      background: linear-gradient(to right, #9a78b4, #9a78b4);
    }

    .image {
      background: #000 no-repeat center center;
      background-size: cover;
      position: absolute;
      right: 0;
      left: 32%;
      bottom: 10px;
      height: 770px;
      z-index: 10;
      transition: opacity 1.5s ease, transform 3.5s ease;
      transform: translateX(-15px);
      opacity: 0;

      &.current {
        transition: opacity 0.35s ease, transform 0.55s ease;
        transform: translateX(0);
        opacity: 1;
        z-index: 11;
      }

      @include respond-down(XL) {

      }

      @include respond-down(L) {
        height: 495px;
        left: 27%;
        right: -15%;
      }

      @include respond-down(M) {
        height: 455px;
        left: 15%;
        right: -25%;
      }

      @include respond-down(S) {
        height: 525px;
        left: 0%;
        bottom: 20px;
      }

      @include respond(XS) {
        height: 338px;
      }
    }

    .index-top {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 20;

      @include respond-down(L) {
        width: 120%;
        left: -10%;
        right: auto;
      }

      @include respond-down(M) {
        width: 160%;
        left: -30%;
      }

      @include respond-down(S) {
        width: 190%;
        left: -60%;
        height: 100%;
      }

      @include respond(XS) {
        width: 300%;
        left: -150%;
        height: 86%;
        top: 130px;
      }
    }
  }

  .index-header-spacer {
    height: 765px;
    position: relative;
    z-index: 25;
    padding-top: 5%;
    overflow: hidden;

    @include respond-down(XL) {
      height: 575px;
    }

    @include respond-down(L) {
      height: 470px;
    }

    @include respond-down(S) {
      height: 535px;
      padding-top: 0;
      margin-top: -20px;
    }
    @include respond(XS) {
      height: 490px;
    }

    .directions-banner {
      display: flex;
      align-items: center;
      width: 53%;

      @include respond-down(M) {
        width: 100%;
        height: 75%;
        flex-direction: column;
        //align-items: flex-start;
        align-items: center;
      }

      @include respond-down(S) {
        width: 100%;
        height: 90%;
      }

      .directions-points {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 40;

        @include respond(M) {
          margin-left: 50%;
        }

        @include respond-down(M) {
          order: 2;
          flex-direction: initial;
        }

        .point {
          display: inline-block;
          padding: 24px;

          @include respond-down(XL) {
            padding: 17px;
          }

          .circle {
            border-radius: 10px;
            background: #fff;
            position: relative;
            display: block;
            transition: opacity 0.15s ease;
            opacity: 0.16;
            width: 18px;
            height: 18px;

            @include respond-down(XL) {
              width: 10px;
              height: 10px;
            }

            @include respond-down(M) {
              opacity: 0.7;
            }

            &:after, &:before {
              content: '';
              border-radius: 100px;
              border: 2px solid #fff;
              position: absolute;
              transform: scale(0.2);
              transition: transform 0.42s ease;

              width: 34px;
              height: 34px;
              left: -10px;
              top: -10px;

              @include respond-down(XL) {
                width: 26px;
                height: 26px;
                left: -10px;
                top: -10px;
              }
            }
          }

          &.current {
            .circle {
              opacity: 1;

              &:after, &:before {
                animation: waves 2.6s linear infinite;
              }
              &:after {
                animation-delay: -1.3s;
              }
            }
          }

          @include respond-up(L){
            &:hover:not(.current) {
              .circle {
                opacity: 1;
                cursor: pointer;

                &:after, &:before {
                  transform: scale(1.2);
                }
                &:after {
                  transition-delay: 0.21s;
                }
              }
            }
          }

          @keyframes waves {
            0% {
              transform: scale(0.2);
              opacity: 1;
            }
            85% {
              opacity: 0.8;
            }
            100% {
              opacity: 0;
              transform: scale(1.5);
            }
          }
        }
      }

      .directions-texts {
        flex: 1 1 100%;
        height: 270px;
        overflow: visible;
        padding-left: 34px;
        position: relative;
        z-index: 30;

        @include respond-down(L) {
          padding-left: 24px;
          height: 250px;
        }

        @include respond-down(M) {
          width: 100%;
          margin-bottom: 30px;
          padding-left: 0;
          order: 1;
        }

        @include respond-down(S) {
          width: 100%;
        }

        .button-white {
          background: #fff;
          color: $col-main;
          border-color: #fff;
          padding-left: 35px;
          padding-right: 35px;

          @include respond-down(L) {
            padding-left: 30px;
            padding-right: 30px;
          }

          @include respond-down(XS) {
            margin-top: 15px;

            &.button-to-right {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }

        .text {
          padding: 20px 0;
          height: 270px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          cursor: default;
          opacity: 0;
          position: absolute;
          top: 0;
          z-index: 10;
          transition: transform 0.25s ease, opacity 0.25s ease;
          align-items: flex-start;

          @include respond-down(L) {
            height: 250px;
          }

          @include respond-down(M) {
            height: auto;
            width: 430px;
          }

          @include respond-down(S) {
            width: 70%;
          }
          @include respond-down(XS) {
            padding-bottom: 0;
            width: 100%;
          }

          .first {
            display: block;
            margin-bottom: 45px;

            @include respond-down(S) {
              margin-bottom: 35px;
            }
            @include respond-down(XS) {
              margin-bottom: 0;
              //min-height: 100px;
            }
          }

          .icon {
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 11px 23px rgba(255,255,255,0.34);
            display: flex;
            align-items: center;
            justify-content: space-around;
            transition: transform 0.10s ease;
            transform: translateX(15px);
            width: 80px;
            height: 80px;
            flex: 0 0 80px;
            margin-bottom: 49px;

            @include respond-down(XL){
              flex: 0 0 56px;
              width: 56px;
              height: 56px;
              margin-bottom: 28px;
            }

            svg {
              width: 50%;
            }

            @include respond-down(M) {
              display: none;
            }
          }

          .intro {
            display: block;
            @include respond-property(font-size, 32px, 32px, 22px, 22px, 22px, 20px);
            font-family: "Cambria", serif;
            font-weight: 400;
            line-height: 1.45em;
            color: rgba(255,255,255,0.9);
            margin-bottom: 30px;
            transition: transform 0.20s ease;
            transform: translateX(30px);

            @include respond-down(M) {
              margin-bottom: 20px;
              line-height: 1.16em;
            }
            @include respond(XS) {
              margin-bottom: 10px;
            }
          }

          .old-price {
            @include respond-property(font-size, 30px, 30px, 20px, 20px, 20px, 16px);
            font-weight: 500;
            color: rgba(255,255,255,0.31);
            position: relative;
            display: inline-block;
            margin-bottom: 1em;
            transition: transform 0.30s ease;
            transform: translateX(30px);

            @include respond-down(XS) {
              margin-bottom: 0.5em;
            }

            &:after {
              content: '';
              position: absolute;
              width: 110%;
              height: 2px;
              background: #fff;
              top: 50%;
              left: -5%;
              transform: rotate(-12deg);
            }
          }

          .current-price {
            @include respond-property(font-size, 40px, 40px, 30px, 25px, 25px, 20px);
            font-weight: 500;
            color: #fff;
            transition: transform 0.4s ease;
            transform: translateX(30px);
          }

          &.current {
            opacity: 1;
            z-index: 20;
            .intro, .icon, .old-price, .current-price {
              transform: translateX(0);
            }
          }

          @include respond-up(L){
            &:hover {
              .intro {
                color: rgba(255,255,255,0.9);
              }
            }
          }
        }
      }
    }
  }

  .index-intro-text {
    margin-bottom: 200px;

    @include respond-down(L) {
      margin-bottom: 170px;
      // margin-top: -62px;
      z-index: 10;
    }

    @include respond-down(M) {
      margin-bottom: 52px;
    }
    @include respond-down(S) {
      margin-top: 0;
      padding-top: 16px;
      margin-bottom: 74px;
    }
    @include respond-down(XS) {
      margin-bottom: 79px;
    }

    .round-logo {
      display: block;
      margin: 0 auto 40px auto;

      @include respond(L) {
        width: 72px;
        margin-bottom: 8px;
        transform: translateX(-50px);
      }

      @include respond-down(S) {
        margin-bottom: 14px;
      }

      .figure {
        fill: $col-active
      }
    }

    .intro {
      position: relative;

      .text-description-wrapper {
        padding-left: 50%;
        position: relative;

        @include respond-down(M) {
          padding-left: 0%;
        }

        .text-description {
          margin-left: -47px;
          padding-right: 10%;
          padding-top: 26px;
          @include respond-down(L) {
            margin-left: -90px;
          }
          @include respond-down(M) {
            margin-left: 0;
            padding: 0;
          }
          @include respond-down(S) {
            padding-right: 0;
            margin-bottom: 20px;
          }

          .lead {
            @include respond-property(font-size, 40px, 40px, 30px, 30px, 25px, 25px);
            line-height: 1.35em;
            font-family: "Cambria";
            font-weight: bold;
            color: $col-bold;
            margin-bottom: 32px;

            @include respond-down(M) {
              text-align: center;
            }
          }

          .text {
            font-size: 16px;
            line-height: 1.65em;
            color: $col-b0;

            @include respond-down(M) {
              // padding-right: 40%;
            }
            @include respond-down(S) {
              padding-right: 0;
            }

            p {
              margin-bottom: 1.4em;
            }
          }

          .end {
            @include respond-property(font-size, 18px, 18px, 16px, 16px, 16px, 14px);
            font-weight: bold;
            line-height: 1.35em;
            color: $col-main;
            margin-bottom: 15px;

            @include respond-down(M) {
              margin-bottom: 45px;
            }
            @include respond-down(S) {
              margin-bottom: 38px;
            }
            @include respond-down(XS) {
              margin-bottom: 21px;
            }
          }
        }
      }

      .text-image {
        position: absolute;
        top: 0;
        left: 100px;

        @include respond-down(L) {
          left: auto;
          right: 60%;
        }
        @include respond-down(M) {
          right: 0%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          position: static;
          width: 100%;
        }
        @include respond-down(S) {
          position: initial;
        }
        .img-wrap{
          position: relative;

          @include respond-down(M) {
            display: none;
          }
        }
        .circle{
          width: 285px;
          height: 285px;
          z-index: -1;
          position: absolute;
          left: -64px;
          bottom: 48px;
          background-color: $col-active;
          box-shadow: 0 4px 15px rgba(187, 168, 125, 0.52);
        }
        .picture {
          background: no-repeat center bottom;
          background-size: contain;
          width: 300px;
          height: 450px;

          // @include respond-down(L) {
          //   width: 241px;
          //   height: 361px;
          // }

          @include respond-down(S) {
            display: none;
          }
        }

        .caption {
          margin-right: 40px;
          margin-left: -40px;
          text-align: right;
          border-top: 2px solid $col-active;
          padding-top: 5px;
          line-height: 1.35em;
          font-size: 12px;
          color: $col-b0;

          @include respond-down(M) {
            // width: 80%;
            // margin: 0 0 50px 0;
            width: 100%;
            margin: 0;
          }
          @include respond-down(S) {
            width: 100%;
          }
          @include respond-down(XS) {
           text-align: center;
          }
          .info {
            margin-bottom: 12px;
            color: $col-active;
            font-style: italic;
            @include respond-down(L) {
              margin-bottom: 6px;
            }
            @include respond-down(M) {
              font-size: 14px;
              line-height: 21px;
              margin-bottom: 0;
            }
            @include respond-down(XS) {
              font-size: 12px;text-align: center;
              display: inline;

              &::after{
                content: ', ';
              }
            }
          }

          .degree {
            font-style: italic;
            margin-bottom: 6px;
            margin-top: 6px;
            @include respond-down(L) {
              margin-bottom: 6px;
            }
            @include respond-down(M) {
              margin-top: 0;
              color: $col-active;
              font-size: 14px;
              margin-bottom: 0;
              line-height: 21px;
            }
            @include respond-down(XS) {
              font-size: 12px;text-align: center;
              display: inline;
            }
          }

          .name {
            font-size: 20px;
            // font-weight: bold;
            color: $col-b0;
            @include respond-down(M) {
              margin-top: 18px;
            }
            @include respond-down(XS) {
              margin-top: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .features-wrapper {
    margin-bottom: 230px;

    @include respond-down(S) {
      margin-bottom: 130px;
    }

    .features-list {
      display: flex;
      justify-content: space-between;

      @include respond-down(S) {
        flex-wrap: wrap;
      }

      .item {
        position: relative;

        @include respond(XS){
          display: flex;
          margin-bottom: 57px;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 100px;
          width: 60px;
          margin-left: -30px;
          height: 10px;
          box-shadow: 0 0 20px 50px $col-main;
          opacity: 0.6;
          border-radius: 10px;

          @include respond-down(L) {
            width: 40px;
            margin-left: -20px;
          }
          @include respond(XS){
            left: 64px - 20px;
            margin: 0;
            transform: scale(0.7);
            top: 85px;
          }
        }

        .box {
          position: relative;
          margin: 35px auto ;
          background: $col-main;
          border-radius: 6px;
          padding: 70px 24px 34px 24px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-around;
          text-transform: uppercase;
          color: #FFF;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.06em;
          line-height: 1.35em;
          width: 182px;
          height: 145px;
          z-index: 10;

          @include respond-down(L) {
            width: 147px;
            height: 117px;
            font-size: 12px;
            margin: 24px auto ;
          }
          @include respond-down(S) {
            width: 204px;
          }
          @include respond-down(XS){
            width: 128px;
            margin: 0;
          }

          .icon {
            width: 64px;
            height: 64px;
            background: $col-active;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            box-shadow: 0 4px 15px rgba(187,168,125,52);
            position: absolute;
            left: 50%;
            margin-left: -32px;
            top: -23px;

            @include respond-down(L) {
              width: 52px;
              height: 52px;
              margin-left: -26px;
            }

            svg {
              width: 50%;
            }
          }
        }

        .after-box {
          &:before {
            content: '';
            display: block;
            width: 182px;
            margin-left: auto;
            margin-right: auto;
            height: 1px;
            background: rgba(112,112,112,0.35);
            margin-bottom: 10px;

            @include respond-down(L) {
              width: 147px + 10px + 10px;
            }

            @include respond-down(M) {
              width: 147px;
            }
            @include respond-down(S) {
              width: 204px;
            }
            @include respond(XS){
              display: none;
            }
          }

          width: 182px + 25px + 25px;
          margin: 0 -25px;
          text-align: center;
          font-size: 16px;
          color: $col-b0;
          line-height: 1.35em;

          @include respond-down(L) {
            width: 147px + 30px + 30px;
            margin: 0 -30px;
            font-size: 16px;
          }
          @include respond-down(M) {
            //width: 147px;
            //margin: 0 0;
            //font-size: 12px;
          }
          @include respond-down(S) {
            width: 204px;
            margin: 0 0 30px 0;
          }
          @include respond(XS){
            width: auto;
            margin: 0;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
            display: flex;
            align-items: center;
            font-size: 15px;
            line-height: 1.45em;
          }
        }
      }
    }
  }

  .directions-wrapper {
    position: relative;
    // background: rgba(255,0,0,0.2);

    .directions-bg {
      position: absolute;
      width: 100%;
      top: -240px;
      height: 860px;
      z-index: 5;

      @include respond-down(L) {
        top: -250px;
        height: 860px;
        left: -20%;
        width: 120%;
      }

      @include respond-down(M) {
        top: -200px;
        height: 680px;
      }

      @include respond-down(S) {
        height: 915px;
        left: -70%;
        width: 260%;
      }

      @include respond(XS) {
        height: 1120px;
      }
    }

    .container {
      @include respond-property(margin-bottom, 140px, 140px, 100px, 90px, 100px, 140px);
      z-index: 15;
      text-align: right;

      .subtitle-wrapper {
        display: flex;

        @include respond-down(L) {
          margin-bottom: 40px;
        }
        @include respond(XS) {
          flex-direction: column;
          text-align: left;
        }

        .h2 {
          color: #fff;

          @include respond(XS) {
            margin-bottom: 16px;
          }
        }

        .subtitle-helper {
          text-align: left;
          width: 325px;
          padding-top: 10px;
          padding-left: 70px;
          font-size: 16px;
          color: #FFF;
          letter-spacing: 0.01em;
          line-height: 1.45em;

          @include respond(XS) {
            padding-left: 0;
          }
        }
      }

      .directions-list {
        margin-bottom: 65px;
        display: flex;
        justify-content: space-between;

        @include respond-down(S){
          flex-wrap: wrap;
        }
        @include respond(XS) {
          margin-left: -12px;
          margin-right: -12px;
        }

        .item {
          cursor: pointer;

          &.all-directions {
            display: none;

            @include respond-down(S){
              display: initial;
            }
          }

          @include respond-down(S){
            width: 30%;
            margin-bottom: 60px;
          }

          @include respond-down(XS){
            width: 50%;
            margin-bottom: 40px;
          }

          .square {
            margin: auto;
            width: 90px;
            height: 90px;
            border-radius: 6px;
            background: #fff;
            box-shadow: 0 11px 23px rgba(255,255,255,0.34);
            display: flex;
            align-items: center;
            justify-content: space-around;
            transition: box-shadow 0.25s ease, transform  0.25s ease;

            svg {
              width: 38%;
            }
          }

          .helper {
            display: block;
            margin: 0 -30px;
            text-align: center;
            font-weight: bold;
            color: #fff;
            padding-top: 25px;

            @include respond-down(L) {
              padding-top: 22px;
              font-size: 16px;
              letter-spacing: 0.019em;
            }
            @include respond-down(XS) {
              margin: 0;
            }
          }

          &:hover {
            .square {
              box-shadow: 0 11px 35px rgba(255,255,255,0.54);
              transform: translateY(-5px);
            }
          }
        }
      }

      .button {
        display: inline-block;

        @include respond-down(S){
          display: none;
        }
      }
    }
  }

  .specialists-wrapper {
    overflow: hidden;
    margin-bottom: 150px;

    @include respond-down(L){
      margin-bottom: 80px;
    }

    @include respond-down(M){
      padding-top: 20px;
    }

    .container-mini {
      @include respond-down(M){
        margin: 0 -25px;
        padding-bottom: 100px;
      }
    }

    .subtitles {
      margin-bottom: 55px;

      @include respond-down(M){
        margin: 0 30px 30px 30px;
      }
    }

    .slider {
      display: flex;
      position: relative;

      .specialists-list {
        margin: 0 -30px;
        overflow: visible;
        flex: 0 0 82.5%;
        width: 82.5%;

        &:before {
          content: '';
          border-top: 2px solid $col-bold;
          margin: 0 25px;
          display: block;
        }

        @include respond-down(L){
          flex: 0 0 90%;
          width: 90%;
        }

        @include respond-down(M){
          flex: 0 0 100%;
          width: 100%;
          margin: 0;
        }

        @include respond-down(S){
          flex: 0 0 130%;
          width: 130%;
        }

        @include respond-down(S){
          flex: 0 0 200%;
          width: 200%;
        }

        .slick-list {
          overflow: visible;

          .slick-slide {
            opacity: 0;
            transition: opacity 0.25s linear;
            overflow: visible;

            &.slick-active {
              opacity: 1;
            }
          }
        }

        .item {
          // width: 230px;
          padding: 12px 0 30px 0;
          overflow: visible;
          margin: 0 25px;

          &.spacer {
            // display: none;

            @include respond-down(S){
              display: initial;
            }
          }

          @include respond-down(L) {
            //width: 189px;
          }

          @include respond-down(M){
            margin: 0 30px;
          }

          .role {
            line-height: 1.35em;
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.056em;
            color: $col-bold;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            margin-right: -15px;

            @include respond-down(L) {
              font-size: 12px;
              margin-bottom: 4px;
            }

            strong {
              letter-spacing: 0.068em;
              display: inline-block;
              width: 25px;
            }
          }

          .name {
            font-size: 17px;
            color: $col-b0;
            padding-left: 25px;
            height: 52px;
            overflow: hidden;
            margin-bottom: 10px;
            margin-right: -25px;

            @include respond-down(L) {
              font-size: 16px;
              margin-bottom: 14px;
            }
          }

          .framed-photo {
            margin-bottom: 32px;

            .photo {
              cursor: pointer;
              width: auto;
              padding-bottom: 134%;

              @include respond-down(L){
                width: auto;
              }
            }
          }

          .button {
            width: 180px;
            text-align: center;
            display: block;
            margin: auto;

            @include respond-down(L){
              width: 148px;
            }
          }
        }
      }

      .right-pane {
        z-index: 30;

        .rounded {
          margin-left: 30px;
          flex-direction: column;
          align-items: flex-start;
          margin-top: -19px;

          @include respond-down(M){
            position: absolute;
            top: -100px;
            right: 0px;
          }
          @include respond(XS){
            display: none;
          }

          .arrow-back {
            margin-left: -7px;
            margin-bottom: 10px;
          }

          .arrow-back-svg {
            transform: rotate(180deg);
          }
        }

        .counters {
          margin: 60px 0 0 30px;
          width: 72px;
          text-align: center;

          @include respond-down(M){
            position: absolute;
            left: 30px;
            right: 30px;
            bottom: -50px;
            margin: 0;
            width: auto;
          }

          .arrows {
            display: flex;
            justify-content: space-between;
            margin-bottom: 38px;

            @include respond-down(M){
              position: absolute;
              width: 42px + 50px + 42px;
              left: 50%;
              margin-left: -(42px + 25px);
            }

            @include respond(XS){
              width: 42px + 20px + 42px;
              left: 0;
              margin-left: 0;
            }

            .round {
              width: 30px;
              height: 30px;
              background: $col-active;
              border-radius: 30px;
              border: 0;
              display: flex;
              align-items: center;
              justify-content: space-around;
              cursor: pointer;

              @include respond-down(M){
                width: 42px;
                height: 42px;
              }

              &.disabled {
                opacity: 0.26;
                cursor: default;
              }
            }
          }
          .text {
            font-family: 'Roboto';
            font-size: 24px;
            letter-spacing: 0.014em;
            color: $col-active;
            font-weight: 500;

            @include respond-down(M){
              text-align: right;
              padding-top: 10px;
            }

            .total {
              color: #EDE8DC;
            }
          }
        }
      }
    }
  }

  .flexed {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;

    @include respond-down(XS){
      order: 2;
    }

    .number-one {
      margin-bottom: 42px;
      padding-right: 10px;
      height: 36px;
      white-space: nowrap;
      margin-top: 20px;
      background: linear-gradient(to right, #CFB986 0%, #B8A579 100%);
      max-width: 600px;
      display: flex;
      border-radius: 6px;
      position: relative;
      padding-left: 100px;
      color: #FFF;
      align-items: center;
      font-weight: 500;

      @include respond-down(L){
        margin-bottom: 30px;
        margin-top: 5px;
        height: 30px;
        padding-left: 70px;
      }
      @include respond-down(M){
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 10px 16px 10px 34px;
      }

      .text {
        margin-right: 20px;
        @include respond-down(XS){
          font-size: 14px;
        }
      }

      .rounded {
        position: absolute;
        width: 56px;
        height: 56px;
        top: 50%;
        margin-top: -28px;
        background: #FFF;
        border-radius: 56px;
        box-shadow: 0 5px 15px #B9A67A;
        align-items: center;
        justify-content: center;
        display: flex;
        color: $col-active;
        font-weight: bold;
        font-size: 25px;
        left: 25px;

        @include respond-down(L){
          left: 16px;
          width: 46px;
          height: 46px;
          margin-top: -23px;
          font-size: 20px;
        }
        @include respond-down(M){
          margin: 0;
          top: -16px;
          left: -16px;
        }

        small {
          font-size: 16px;
          margin-top: 5px;
          margin-right: 2px;

          @include respond-down(L){
            font-size: 14px;
            margin-top: 4px;
          }
        }
      }

      img {
        height: 24px;
        margin-bottom: -5px;
        @include respond-down(L){
          height: 20px;
          margin-bottom: -4px;
        }
        @include respond-down(XS){
          margin-left: -4px;
        }
      }
    }
  }


  .google-comments-wrapper {
    text-align: right;

    @include respond-down(M){
      text-align: center;
    }

    .subtitle-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 50px;

      @include respond-down(S){
        align-items: flex-end;
      }
      @include respond(XS){
        flex-direction: column;
        margin-bottom: 30px;
        align-items: center;
      }

      .subtitles {
        margin-bottom: 0;

        @include respond-down(S){
          text-align: right;
          width: 170px;
        }
        @include respond(XS){
          margin-bottom: 20px;
          white-space: nowrap;
          width: 100%;
          text-align: center;
        }
      }

      .subtitle-helper {
        display: flex;
        align-items: center;

        @include respond(XS){
          order: 2;
          text-align: left;
          width: 100%;
        }

        svg {
          width: 29px;
          margin-right: 10px;
        }
        .gm {
          font-size: 18px;
          margin-right: 10px;
        }
        .link {
          font-size: 16px;
          text-decoration: underline;
          color: $col-b1;
        }
      }
    }

    .comments-items-wrapper {
      margin-bottom: 40px;
      position: relative;

      &:after, &:before {
        content: '';
        position: absolute;
        height: 14px;
        right: 6px;
        left: 0;
        top: 0;
        background: linear-gradient(to bottom, #fff, rgba(255,255,255,0.0));
      }

      &:before {
        top: auto;
        bottom: 0;
        background: linear-gradient(to top, #fff, rgba(255,255,255,0.0));
      }
    }

    .comments-items {
      text-align: left;
      max-height: 400px;
      overflow: auto;
      padding-top: 14px;
      padding-bottom: 14px;

      .item {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid #CDCDCD;
        margin-bottom: 20px;
        margin-right: 60px;

        @include respond(XS){
          margin-right: 30px;
        }

        .image {
          flex: 0 0 48px;
          width: 48px;
          height: 48px;
          border-radius: 48px;
          background: #333 no-repeat center center;
          background-size: cover;

          @include respond(XS){
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
          }
        }

        .comment {
          flex: 0 0 95%;
          padding-left: 25px;

          .name {
            font-size: 16px;
            font-weight: bold;
            color: $col-b0;
            margin-bottom: 10px;
            @include respond(XS){
              font-size: 14px;
            }
           }

          .rate {
            margin-left: -4px;
            display: flex;
            align-items: center;

            .stars-rate {
              margin-right: 8px;
            }

            .time {
              font-size: 14px;
              color: #cdcdcd;
              margin-bottom: 3px;

              @include respond(XS){
                font-size: 12px;
                margin-bottom: 0px;
              }
            }
          }

          .message {
            font-size: 16px;
            color: $col-b0;
            margin-bottom: 20px;
            line-height: 1.45em;

            @include respond(XS){
              font-size: 12px;
              margin-bottom: 12px;
            }
          }
        }
      }

      .button {
        margin-left: 72px;
        margin-bottom: 50px;

        @include respond-down(S) {
          margin-left: 60px;
          margin-bottom: 0px;
        }
      }
    }

    .button {
      display: inline-block;
    }
  }

  .main-header{
    padding-top: 35px;
    text-align: center;

    @include respond-down(L){
      padding-top: 80px;
    }
    @include respond-down(M){
      padding-top: 35px;
    }

    &__title{
      font-size: 40px;
      font-weight: bold;
      color: $col-bold;
      line-height: 1;
      @include respond-down(XS){
        font-size: 35px;
      }
      .text{
        margin-top: 14px;
        margin-bottom: 68px;

        @include respond-down(L){
          margin-bottom: 39px;
        }
        @include respond-down(XS){
          margin-top: 12px;
          margin-bottom: 31px;
        }
      }
      .clinic{
        font-size: 16px;
        color:$col-active;
        font-weight: 500;
        letter-spacing: 0.1em;
      }
    }

    .logo{
      margin-bottom: 61px;

      @include respond-down(L){
        margin-bottom: 39px;
      }
      @include respond-down(S){
        margin-bottom: 42px;
      }
      @include respond-down(XS){
        margin-bottom: 31px;
      }
      svg{
        width: 122px;
        height: 125px;
        fill: $col-active;
        @include respond-down(L){
          width: 101px;
          height: 104px;
        }
        @include respond-down(S){
          width: 96px;
          height: 99px;
        }
        @include respond-down(XS){
          width: 100px;
          height: 103px;
        }
      }
    }

    .desc {
      width: 100%;
      max-width: 560px;
      font-size: 16px;
      line-height: 1.65em;
      margin-bottom: 34px;
      margin-left: auto;
      margin-right: auto;
      color: $col-b0;

      @include respond-down(S){
        max-width: 510px;
      }

      &:last-child{
        margin-bottom: 0;
      }

      .special{
        font-weight: bold;
      }
    }
  }

  .excursion{
    @include respond-property(margin-top, 241px, 241px, 222px, 227px, 212px, 183px);
    @include respond-property(margin-bottom, 120px, 120px, 100px, 86px, 75px, 53px);
  }

}

.contacts-wrapper {
  margin-bottom: 200px;
  padding-top: 236px;
  position: relative;

  @include respond-down(L){
    padding-top: 186px;
  }
  @include respond-down(S) {
    padding-top: 86px;
    margin-bottom: -30px;
  }

  .subtitles {
    margin-bottom: 60px;

    @include respond-down(L){
      margin-bottom: 50px;
    }
  }

  .contact-items {
    .item {
      position: relative;
      padding-left: 18px + 25px;
      margin-bottom: 27px;

      svg {
        position: absolute;
        left: 0;
        top: 0;
        height: 30px;

        @include respond-down(L){
          top: -3px;
        }
      }

      .title {
        font-weight: bold;
        font-size: 22px;
        color: $col-bold;
        line-height: 1.35em;
        margin-bottom: 12px;

        @include respond-down(L){
          font-size: 18px;
          margin-bottom: 8px;
        }
      }

      .data {
        font-size: 19px;
        color: $col-b0;

        .first {
          font-weight: bold;
        }

        .sep {
          font-weight: bold;
          color: $col-main;
          padding-left: 5px;
          padding-right: 5px;
        }

        @include respond-down(L){
          font-size: 16px;
        }

        .link {
          margin-right: 10px;
          white-space: nowrap;
          color: $col-bold;
          text-decoration: underline;
        }

      }
    }
  }

  .map-box {
    top: 194px;
    width: 47%;
    right: 0;
    position: absolute;

    .button-attention {
      position: absolute;
      z-index: 30;
      left: 132px;
      top: 10px;

      @include respond-down(XL) {
        left: 132px;
        top: 10px;
      }

      @include respond-down(S) {
        left: 5%;
        top: auto;
        bottom: 350px;
      }

      @include respond-down(XS) {
        left: 50%;
        margin-left: -78px;
        bottom: 350px;
      }
    }

    @include respond-down(L){
      top: 120px;
    }

    @include respond-down(S){
      position: initial;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .map-container {
      height: 450px;
      background: #eee;
      position: relative;
      z-index: 15;

      @include respond-down(L){
        height: 420px;
      }
      @include respond-down(S){
        height: 420px;
        order: 2;
      }
    }

    .map-helper {
      position: relative;
      z-index: 10;
      background: #fff;
      box-shadow: 0 6px 22px rgba(211, 213, 235, 0.9);
      padding: 20px;

      @include respond-down(S){
        order: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        z-index: 20;
      }

      .address {
        font-size: 22px;
        color: #081826;
        margin-bottom: 10px;

        @include respond-down(L){
          font-size: 18px;
        }
        @include respond-down(S){
          margin-bottom: 0;
        }
        @include respond(XS){
          margin-bottom: 10px;
        }
      }

      .link {
        margin-left: 10px;
        display: flex;
        align-items: flex-end;

        .text {
          text-transform: uppercase;
          border-bottom: 1px solid $col-bold;
          color: $col-bold;
          margin-left: 10px;
          font-size: 14px;
          letter-spacing: 0.05em;
          font-weight: 600;
        }
      }
    }
  }
}
