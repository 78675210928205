.excursion-block{
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 19px rgba(154, 121, 180, 0.38);
  background-color: $white;
  position: relative;
  border-radius: 6px;

  @include respond-property(padding, 12px, 10px, 10px, 8px, 8px, 5px);
  @include respond-property(width, 1000px, 876px, 803px, 100%, 100%, 100%);

  &__map{
    background-color: #333;
    width: 100%;
    height: 100%;

    .map-content {
      iframe {
        display: block;
        height: 250px;
        @include respond-property(height, 500px, 430px, 400px, 340px);
      }
    }
  }
}

.circle-wrap{
  position: absolute;
  @include respond-property(top, -136px, -110px, -102px, -102px, -102px, -96px);
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.circle{
  border-radius: 50%;
  box-shadow: 0 0 99px #ECEEFF;
  position: relative;
  @include respond-property(width, 445px, 408px, 374px, 374px, 374px, 259px);
  @include respond-property(height, 445px, 408px, 374px, 374px, 374px, 259px);
  &__text{
    text-align: center;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    color: $col-bold;
    @include respond-property(padding-top, 60px, 54px, 48px, 48px, 48px, 40px);
    @include respond-property(font-size, 18px, 16px, 16px, 16px, 16px, 14px);
    @include respond-property(line-height, 25px, 20px, 22px, 22px, 22px, 22px);
  }
  
}