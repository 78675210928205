.top-alert-pane {
  background: #DBC48F;
  background: linear-gradient(to right, #DBC48F, #B19A64);
  overflow: hidden;

  &.instagram {
    color: #FFF;
    position: relative;

    .hands {
      position: absolute;
      left: 0;
      width: 210px;
      transform: scale(-1, 1);
      z-index: 10;

      @include respond-down(M){
        transform: scale(1, 1);
        left: auto;
        right: 0;
        top: 40px;
      }

    }

    .container {
      position: relative;
      z-index: 20;

      @include respond-down(M){
        flex-direction: column;
        height: 220px;
        align-items: flex-start;
        justify-content: center;
      }
    }

    .insta-txt {
      position: relative;
      flex: 0 0 50%;
      font-size: 40px;
      line-height: 1em;
      z-index: 20;

      @include respond-down(L){
        font-size: 30px;
        line-height: 1.1em;
      }
      @include respond-down(M){
        //font-size: 40px;
        flex: 0 0 70px;
        margin-bottom: 20px;
      }
    }

    .social-links {
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 50;

      @include respond-down(M){
        flex: 0 0 70px;
      }

      .sl {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 40px 0 0;

        @include respond-down(L){
          margin: 0 20px 0 0;
        }

        img {
          width: 50px;
          display: block;
          margin-bottom: 10px;
          transition: transform 0.15s ease;

          @include respond-down(L){
            width: 46px;
          }
          @include respond-down(M){
            width: 52px;
          }
        }

        .insta-link {
          padding: 2px 6px;
          border-radius: 4px;
          background: #FFFFFF;
          font-size: 14px;
          color: #9A79B4;
          transition: box-shadow 0.15s ease, color 0.15s ease;

          @include respond-down(L){
            padding: 1px 4px;
          }
          @include respond-down(M){
            display: none;
          }
        }
        @include respond-up(L){
          &:hover {
            img {
              transform: translateY(-5px);
            }
            .insta-link {
              box-shadow: 0 2px 7px rgba(0,0,0,0.2);
              color: #664b7c;
            }
          }
        }
      }
    }


    .i-pict {
      position: absolute;
      z-index: 10;
      animation: tudasuda 5s ease-in-out infinite;

      &.p-03 { width: 5%; left: 22%; top: -20px;      animation-duration: 5.31s; animation-delay: -0.3 }
      &.p-04 { width: 60px; left: 16%; bottom: 0%;    animation-duration: 5.37s; animation-delay: -0.5; z-index: 2 }
      &.p-05 { width: 6%; left: 83%; top:  31px;      animation-duration: 5.71s; animation-delay: -0.7 }
      &.p-06 { width: 17px; left: 33%; top: 129px;      animation-duration: 6.01s; animation-delay: -0.9 }
      &.p-07 { width: 5%; left: 86%; bottom: 95px;   animation-duration: 6.09s; animation-delay: -1.2; z-index: 7 }
      &.p-08 { width: 94px; left: 89%; bottom: 13px;  animation-duration: 6.57s; animation-delay: -1.5; z-index: 8 }
      &.p-09 { width: 20px; left: 44%; top: 16px;     animation-duration: 6.81s; animation-delay: -1.7 }

      @include respond-down(L){
      }
      @include respond-down(M){
        &.p-05 { left: 63% }
        &.p-07 { left: 56%; }
        &.p-08 { left: 69%; }
      }
    }

    @keyframes tudasuda {
      0% { transform: translateY(-10px); }
      50% { transform: translateY(10px); }
      100% { transform: translateY(-10px); }
    }

    .insta-txt {
      position: relative;
      z-index: 30;
      padding-left: 240px;

      @include respond-down(L){
        padding-left: 190px;
      }
      @include respond-down(M){
        padding-left: 0px;
        & > span {
          display: block;
        }
      }

      .first-w {
        @include respond-down(M){
          display: block;
          font-weight: bold;
        }
      }

      .insta-link {
        background: #fff;
        border-radius: 4px;
        padding: 8px 18px;
        color: #9A79B4;
        margin-left: 20px;
        box-shadow: 0 3px 10px rgba(0,0,0,0.1);
        position: relative;

        @include respond-down(M){
          margin-left: 0;
          margin-top: 20px;
          display: inline-block;
        }


        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          background: #fff;
          left: -7px;
          transform: rotate(45deg);
          top: 50%;
          margin-top: -10px;

          @include respond-down(M){
            top: -7px;
            margin: 0 0 0 -10px;
            left: 50%;
          }
        }
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond-property('height', 180px, 160px, 130px, 160px, 160px, 160px);

    @include respond-down(XS){
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .circles {
      width: 12px;
      height: 12px;
      position: absolute;
      z-index: 10;
      margin-left: -6px;
      margin-top: -6px;
      background: rgba(250,0,0,0.1);
      left: 50%;
      top: 50%;

      &:after, &:before {
        content: '';
        position: absolute;
        width: 182px;
        height: 182px;
        left: -86px;
        top: -86px;
        border: 1px solid #FFF;
        border-radius: 380px;
        transform: scale(1);
        transition: transform 0.42s ease;
        animation: waves 2.6s linear infinite;
      }

      &:before {
        animation-delay: -1.3s;
      }

      @keyframes waves {
        0% {
          transform: scale(0.3);
          opacity: 1;
        }
        85% {
          opacity: 0.8;
        }
        100% {
          opacity: 0;
          transform: scale(1.5);
        }
      }

    }

    .phone-wrapper {
      @include respond-property('width', 220px, 168px, 132px, 120px);
      position: absolute;
      top: 0;

      @include respond-down(M){
        left: -70px;
      }
      @include respond-down(S){
        display: none;
      }

      .phone {
        width: 100%;
        position: relative;
        z-index: 20;
      }
    }

    .message {
      @include respond-property('margin-left', 380px, 280px, 230px, 80px, 0, 0);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include respond-down(XS){
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      .text-m {
        margin-right: 30px;
      }

      .h2 {
        color: #FFF;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.35em;
        margin-bottom: 0.4em;
        @include respond-property('font-size', 40px, 35px, 25px);
      }

      .lead {
        color: #FFF;
        font-size: 14px;
        line-height: 1.35em;
        @include respond-property('font-size', 20px, 18px);

        @include respond-down(S) {
          br { display: none }
        }

        @include respond-down(XS){
          margin-bottom: 16px;
        }
      }

    }

    .button {
      background: #FFF;
      color: #CFB986;
      margin-right: 30px;
      box-shadow: 5px 5px 12px rgba(61, 76, 125, 0.14);
      position: relative;
      z-index: 40;

      @include respond-down(S){
        white-space: nowrap;
        padding-left: 8px;
        padding-right: 8px;
        margin-right: 15px;
        margin-left: 10px;
      }
      @include respond-down(XS){
        margin-left: 0;
        padding-left: 16px;
        padding-right: 16px;
        margin-bottom: 10px;
      }

      &:hover {
        color: #CFB986;
      }
    }

    .messengers {
      display: flex;
      align-items: center;

      @include respond-down(S){
        margin-right: -15px;
        flex: 0 0 auto;
      }
      @include respond-down(XS){
        position: absolute;
        right: 0;
        bottom: 0;
      }

      .m {
        margin-top: 16px;
        position: relative;

        &.one {
          margin-right: -10px;

          @include respond-down(S){
            margin-right: -2px;
          }
          @include respond-down(XS){
            position: absolute;
            top: -44px;
            right: 2px
          }

          img {
            width: 96px;

            @include respond-down(M){
              width: 74px;
            }
            @include respond-down(S){
              width: 60px;
            }
            @include respond-down(XS){
              width: 50px;
            }
          }


        }

        &.two {
          margin-right: -6px;

          @include respond-down(S){
            margin-right: -2px;
          }

          img {
            width: 74px;

            @include respond-down(M){
              width: 54px;
            }
            @include respond-down(S){
              width: 60px;
            }
            @include respond-down(XS){
              width: 50px;
            }
          }
        }

        &.three {
          @include respond-down(S){
            margin-right: -2px;
          }
          @include respond-down(XS){
            margin-right: 2px;
          }

          img {
            width: 54px;

            @include respond-down(M){
              width: 40px;
            }
            @include respond-down(S){
              width: 60px;
            }
            @include respond-down(XS){
              width: 50px;
            }
          }
        }

        @include respond-down(S){
          margin-left: -4px;
          margin-right: -4px;
        }

        .circles {
          margin-top: -18px;

          @include respond-down(S){
            left: 220px;
          }

          @include respond-down(XS){
            left: 80px;
            top: 130px;
          }
        }

        img {
          position: relative;
          z-index: 20;
        }
      }
    }
  }
}
