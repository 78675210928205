.landing-page {
  .breadcrumbs {
    margin-bottom: 50px;
  }

  .submits .button .default {
    padding: 0;
    margin: 0;

  }

  .land-banner {
    width: 100%;
    position: relative;
    padding-bottom: 45%;
    background: #eee;
    margin-bottom: 130px;

    @include respond-down(M) {
      padding-bottom: 0;
    }
    @include respond-down(S) {
      margin-bottom: 90px;
    }

    .bg-image {
      opacity: 0.4;
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #eee no-repeat center center;
      background-size: cover;
    }

    .land-banner-content {
      position: absolute;
      z-index: 20;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      @include respond-down(M) {
        position: relative;
        padding: 50px;
      }

      .h1 {
        font-size: 65px;
        text-align: center;
        height: 300px;
        max-width: 1100px;
        margin: 0 auto 40px auto;
        display: flex;
        align-items: center;

        @include respond-down(L) {
          font-size: 50px;
          margin: 0 auto 20px auto;
        }
        @include respond-down(M) {
          font-size: 40px;
          height: auto;
          margin-bottom: 50px;
        }
      }

      .land-steps {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;

        @include respond-down(M) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 30px;
        }

        .step {
          max-width: 18%;
          margin: 0 50px;
          display: flex;
          font-family: 'Ubuntu', sans-serif;
          align-items: center;

          @include respond-down(L) {
            max-width: 25%;
            margin: 0 20px;
          }
          @include respond-down(M) {
            max-width: 100%;
            margin-bottom: 50px;
          }

          strong {
            display: block;
            color: #C2AB75;
            font-size: 85px;
            font-weight: 700;
            margin-right: 20px;

            @include respond-down(L) {
              font-size: 75px
            }
          }

          span {
            font-size: 20px;

            @include respond-down(L) {
              font-size: 18px;
            }
          }

        }
      }

      .button {
        font-size: 17px;
        margin-bottom: 50px;
      }
    }
  }

  .subtitles {
    margin-bottom: 70px;
    text-align: center;
  }

  .about-segment {
    width: 994px;
    margin: 0 auto 120px auto;
    border-bottom: 1px solid #CFBA88;

    @include respond-down(M) {
      width: 100%;
    }
    @include respond-down(S) {
      margin-bottom: 90px;
    }

    .about-paragraph {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;

      b, strong {
        font-weight: bold;
      }

      i {
        font-style: italic;
      }

      @include respond-down(S) {
        flex-direction: column;
        margin-bottom: 30px;
      }

      .col {
        //max-width: 48%;
        width: 48%;

        @include respond-down(S) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }

    .about-images {
      margin-bottom: 100px;
      display: flex;
      justify-content: space-between;

      @include respond-down(S) {
        flex-direction: column;
      }

      img {
        height: 204px;

        @include respond-down(M) {
          height: auto;
          width: 30%;
        }

        @include respond-down(S) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .steps-segment {
    max-width: 670px;
    margin: 0 auto 180px auto;

    @include respond-down(S) {
      margin-bottom: 90px;
    }

    .steps-list {
      margin-bottom: 60px;

      .s-item {
        margin-bottom: 30px;

        .way {
          box-shadow: 0px 0px 10px 0px #FFFFFFA6;
          color: #000;
          margin-bottom: 0 !important;

          .rounded {
            box-shadow: 0px 5px 20px 0px #C2AB7580;
            font-size: 30px;
            width: 54px;
            height: 54px;
            flex: 0 0 54px;

            @include respond-down(L) {
              display: flex;
            }
          }
        }

        .info {
          line-height: 1.65em;
          font-size: 16px;
          margin-left: 70px;

        }
      }
    }

    .quotable-item {
      border: none;
      background: rgba(#9B7EB2, 0.1);
      border-radius: 9px;
      color: #573871;
      font-size: 12px;
      line-height: 18px;
      padding: 20px 30px;
      margin: 0 50px;
    }

    .list-type-dot {
      font-style: initial;
      padding-top: 34px;
      .item {
        margin-bottom: 15px !important;
      }
    }
  }

  .prices-segment {
    margin-bottom: 180px;

    @include respond-down(S) {
      margin-bottom: 90px;
    }

    .subtitles {
      margin-bottom: 25px;
    }

    .lead {
      max-width: 980px;
      margin: 0 auto 36px auto;
      font-size: 20px;
      line-height: 1.55em;
      text-align: center;
    }

    .prices-items {
      max-width: 706px;
      margin: auto ;
    }
  }

  .call-me-segment {
    margin-bottom: 180px;
    text-align: center;

    @include respond-down(S) {
      margin-bottom: 90px;
    }

    .form-control {
      font-size: 18px;
      width: 310px;
      padding: 0 27px;
      height: 56px;
      margin-bottom: 22px;

      @include respond-down(M){
        width: 250px;
        font-size: 14px;
        padding: 0 20px;
        margin-bottom: 17px;
      }
      @include respond-down(S){
        margin-bottom: 18px;
        width: 100%;
      }
    }

    .button {
      width: 310px;
      padding: 0 27px;
      height: 50px;
      font-size: 20px;
      font-weight: bold;

      @include respond-down(M){
        width: 250px;
        font-size: 14px;
        padding: 0 20px;
        margin-bottom: 17px;
      }
      @include respond-down(S){
        margin-bottom: 18px;
        width: 100%;
      }
    }

    .subtitles {
      margin-bottom: 25px;
    }

    .lead {
      margin-bottom: 60px;
    }

    .call-me-box {
      max-width: 420px;
      margin: 0 auto 40px auto;

      .submits {
        .button {
          justify-content: center;
        }
      }
    }
  }

  .profiles-segment {
    border-bottom: 1px solid #CFBA88;
    margin-bottom: 180px;

    @include respond-down(S) {
      margin-bottom: 90px;
    }

    .profiles-list {
      border-top: none;
    }
  }
}