.galery{
  position: relative;
  @include respond-property(margin-top, 152px, 125px, 92px, 72px, 64px, 60px);

  h1{
    text-align: center;
  }

  .slick-slide{
    margin: 0 9px;
    // height: 435px;
    position: relative;

    @include respond-down(XS){
      margin: 0;
    }
    &.slick-current{
      .galery-item{
        // height: 435px;
        &__desc{
          @include respond-down(M){
            opacity: 1;
          }
        }
      }
    }
  }

  .slick-track, .draggable{
    height: 480px;
    @include respond-down(XS){
      height: auto
    }
  }
  .slick-disabled{
    opacity: 0.26;
  }

  .slider-btns{
    margin-top: 28px;
    width: 133px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    @include respond-down(S){
      width: 151px;
    }
    @include respond-down(XS){
      width: 106px;
      margin-top: 26px;
      margin-right: auto;
    }
  
    .slick-next, .slick-prev{
      width: 41.72px;
      height: 41.72px;
      display: flex;
      justify-content: center;align-items: center;
      background-color: $col-active;
      border: none;
      border-radius: 50%;
      svg{
        fill: $white;
      }
    }
    .slick-next{
      
      svg{
        margin-left: 2px;
      }
    }
    .slick-prev{
     
      svg{
        transform: rotate(-180deg);
        margin-right: 2px;
      }
    }
  }

  .slider-counter{
    display: none;
    position: absolute;
    bottom: 50%;
    right: 0;
    transform: translateY(50%);
    font-size: 24px;
    font-weight: 500;
    color: $col-active;
    @include respond-down(M){
      display: block;
    }
    @include respond-down(S){
      bottom: 0;
      transform:none;
    }
    @include respond-down(XS){
      bottom: 50%;
      transform: translateY(50%);
    }
  }

  .wrap-slider-info{
    position: relative;
  }
}

.galery-list {
  @include respond-property(margin-top, 90px, 79px, 38px, 48px, 37px, 29px);
  @include respond-property(height, 960px, 800px, 648px, auto, auto, auto);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: space-between;
  position: relative;
  
  @include respond-down(M){
    display: block;
  }

  &::before,
  &::after {
    content: "";
    flex-basis: 100%;
    width: 0;
    order: 2;
  }
}

.galery-item {
  transition: all 0.25s ease;
  cursor: default !important;
  display: block;
  width: 29.78%;
  position: relative;
  border-radius: 3px;
  box-sizing: border-box;
  height: 62.9%;


  @include respond-down(M){
    position: absolute;
    height: auto;
    width: max-content;
    top: 50%;
    transform: translate(0, -50%);
  }
  @include respond-down(XS){
    position: static;
    transform: none;
  }
  
  &.hover {
    box-shadow: 0 3px 21px #D0D2DF;

    @include respond-down(M){
      box-shadow: none;
    }
  }
  &.hover &__bg{
    opacity: 1;
  }
  
  &--margin-bottom{
    @include respond-property(margin-bottom, 66px, 57px, 26px, 0, 0, 0);
  }
  &:nth-child(2n){
    height: 29.9%;
    @include respond-down(M){
      height: 346px;
    }
  }

  &:nth-child(3n+1) { order: 1; }
  &:nth-child(3n+2) { order: 2; }
  &:nth-child(3n)   { order: 3; }
  
  &__bg{
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $col-bg-item;
    opacity: 0;
    transition: all 0.25s ease;

    @include respond-down(M){
      display: none;
    }
  }
  
  &__desc{
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $white;
    padding: 18px 16px 13px;
    width: 290px;
    @include respond-property(padding, 18px 16px 13px, 13px 14px 9px, 12px 12px 9px, 0, 0, 0);
    
    @include respond-down(XL){
      padding: 13px 14px 9px;
      width: 242px;
    }
    @include respond-down(L){
      padding: 12px 12px 9px;
      width: 202px;
    }
    @include respond-down(M){
      padding: 0;
      width: auto;
      position: static;
      margin-top: 19px;
      transition: all 0.25s ease;
      opacity: 0;
    }
    .line{
      height: 2px;
      width: 35.5px;
      background-color: $col-main;

      @include respond-down(XL){
        width: 29.6px;
      }
      @include respond-down(L){
        width: 24.8px;
      }
      @include respond-down(M){
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__text{
    margin-top: 11px;
    font-size: 16px;
    color: $col-b0;
    font-weight: 500;
    line-height: 1;

    @include respond-down(XL){
      margin-top: 9.5px;
      font-size: 14px;
    }
    @include respond-down(L){
      margin-top: 7.7px;
      font-size: 12px;
    }
    @include respond-down(M){
      font-size: 14px;
      text-align: center;
    }
  }
  img{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;

    @include respond-down(M){
      position: static;
      max-height: 435px;
    }
    @include respond-down(XS){
      position: static;
      max-height: 403px;
      object-fit: contain;
    }
  }
  .icon-green{
    top: 50%;
    transform: translate(-50%, -50%);
  }
}








