.footer-block {
  padding-top: 62px;
  padding-bottom: 140px;
  position: relative;
  overflow: hidden;
  z-index: 20;

  @include respond-down(M) {
    padding-top: 50px;
    padding-bottom: 130px;
  }


  .obercon-logo {
    position: absolute;
    left: 50%;
    bottom: -6px;
    margin-left: -58px;

    .figure {
      fill: #d6bba5;
      transition: fill 0.25s ease;
    }

    &:hover {
      .figure {
        fill: #2B2B32;
      }
    }
  }

  .pay-line {
    text-align: center;
    margin-bottom: 50px;

    @include respond-down(S) {
      margin-bottom: 30px;
    }

    img {
      width: 270px;
      display: inline-block;
      ///background: #FFF;
      padding: 10px;
      border-radius: 4px;
    }
  }

  .footer-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    @include respond-down(L){
      width: 120%;
      left: -10%;
      right: -10%;
    }

    @include respond-down(M){
      width: 160%;
      left: -30%;
      right: -30%;
    }

    @include respond-down(S){
      width: 260%;
      left: -80%;
      right: -80%;
    }
  }

  .container {
    .round-logo {
      display: block;
      margin: 0 auto 56px auto;

      @include respond-down(L){
        margin-bottom: 36px;
      }

      .figure {
        fill: #fff
      }
    }

    .links-list {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 56px;

      &.desktop-only {
        @include respond-down(M){
          display: none;
        }
      }

      @include respond-down(L){
        margin-bottom: 48px;
      }
      @include respond-down(M){
        justify-content: space-between;
      }
      @include respond-down(S){
        flex-direction: column;
        margin-bottom: 20px;
      }

      .item {
        font-size: 14px;
        letter-spacing: 0.1em;
        font-weight: 500;
        text-transform: uppercase;
        transition: color 0.25s ease;
        color: rgba(255,255,255,0.65);

        &:not(:last-child):after {
          padding: 0 18px;
          content: '|';
          color: #D6C4EC;
          font-weight: bold;

          @include respond-down(L){
            padding: 0 10px;
            content: '';
          }
          @include respond-down(S){
            display: none;
          }
        }

        @include respond-down(L){
          font-size: 12px;
        }

        @include respond-down(S){
          padding: 10px;
        }

        @include respond-up(M){
          &:hover {
            color: rgba(255,255,255,1);
          }
        }
      }
    }

    .license-cols {
      margin: auto;
      max-width: 680px;
      display: flex;
      align-items: center;

      @include respond-down(M){
        max-width: 100%;
      }
      @include respond-down(S){
        flex-direction: column;
        text-align: center;
      }

      .helper-text {
        flex: 1 1 70%;
        color: rgba(255,255,255,0.55);
        font-size: 13px;
        padding-right: 7%;
        line-height: 1.35em;

        @include respond-down(L){
          font-size: 12px;
        }
        @include respond-down(S){
          flex: 0 0 100%;
          margin-bottom: 16px;
          padding-right: 10%;
          padding-left: 10%;
        }
        @include respond(XS){
          font-size: 10px;
        }
      }

      .certificate {
        display: inline-block;
        min-width: 180px;
      }
    }
  }
}