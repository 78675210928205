.menu-wrapper {
  position: relative;
  padding: 48px 0;
  z-index: 100;

  @include respond-down(XL) {
    padding: 38px 0;
  }

  @include respond-down(L) {
    padding: 30px 0;
  }

  @include respond(XS) {
    padding-top: 30px;
    padding-bottom: 14px;
  }

  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $col-active;
    padding-bottom: 40px;

    @include respond-down(L) {
      padding-bottom: 22px;
    }
    @include respond-down(M) {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .logo-href {
    .logo-ny-w {
      display: block;
      margin: -24px 0 0 -20px;
    }
    .logo-ny {
      display: none;
      margin: -24px 0 0 -20px;
    }

    .logo-mobile {
      width: 180px;
      // display: none;

      @include respond-down(M) {
        display: block;
      }

      @include respond-down(S) {
        width: 160px;
      }
      @include respond-down(XS) {
        width: 150px;
      }

      .figure {
        fill: $col-bold;
      }

      .text {
        fill: #b6a4c6;
      }
    }
  }

  .nav-top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include respond-down(M) {
      margin-right: 46px;
    }
    @include respond-down(S) {
      margin-right: 60px;
    }
    @include respond-down(XS) {
      display: none;
    }

    .separator {
      margin: 0 18px;
      width: 2px;
      height: 20px;
      background: #D6C4EC;

      @include respond-down(L) {
        margin: 0 10px;
      }
      @include respond-down(S) {
        display: none;
      }
    }

    .top-address {
      color: $col-bold;
      font-size: 16px;
      margin-right: 24px;

      @include respond-down(L) {
        font-size: 14px;
      }
      @include respond-down(S) {
        display: none;
      }
    }

    .button {
      box-shadow: 5px 5px 12px rgba(61, 76, 125, 0.16);
      svg {
        path {
          fill: #FFF
        }
      }

      @include respond-down(M) {
          display: none;
        }
    }
    .phone {
      color: $col-bold;
      transition: color 0.25s ease;
      font-size: 19px;
      letter-spacing: 0.05em;
      font-weight: 500;
      @include respond-down(L) {
        font-size: 16px;
      }

      // @include respond-down(M) {
      //   display: none;
      // }

      @include respond-up(M) {
        &:hover {
          color: $col-active;
        }
      }
    }
  }

  .nav-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;

    @include respond-down(L) {
      margin-top: 22px;
    }
    @include respond-down(M) {
      display: none;
    }

    .item {
      color: $col-bold;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 0.05em;
      line-height: 18px;
      position: relative;
      transition: color 0.25s ease;
      font-weight: 500;

      @include respond-down(XL) {
        letter-spacing: 0.06em;
        font-size: 18px;
        font-weight: bold;
        line-height: 16px;
      }

      @include respond-down(L) {
        line-height: 20px;
        font-size: 16px;
      }

      &:before {
        display: inline-block;
        content: '';
        height: 9px;
        width: 9px;
        position: absolute;
        top:-18px;
        left: 50%;
        border: 2px solid $col-active;
        border-radius: 10px;
        opacity: 0;
        transform: translate(-50%, 0);
        transition: opacity 0.15s ease, transform 0.25s ease;

        @include respond-down(XL) {
          height: 8px;
          width: 8px;
        }

        @include respond-down(L) {
          height: 4px;
          width: 4px;
          top:-12px;
        }

        @include respond-down(M) {}
      }

      &.current {
        color: $col-active;

        &:before {
          opacity: 1;
          transform: translateX(0);
          transform: translate(-50%, 0);
        }
      }

      @include respond-up(L) {
        &:hover {
          color: $col-active;

          &:before {
            opacity: 1;
            // transform: translateX(0);
          }
        }
      }



    }

    .pict {
      width: 40px;
      margin: -5px -16px -9px 10px;

      @include respond-down(L) {
        width: 34px;
      }
    }
  }

  .right-wrapper {
    position: relative;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

  }
}

.menu-opener, .menu-phone-opener {
  display: none;
  margin: 0 0 0 24px;
  width: 48px;
  height: 48px;
  background: $col-active;
  border-radius: 48px;
  box-shadow: 0 4px 15px rgba(187,168,125,52);
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  z-index: 999;
  transform: translate3d(0px, 0, 1px);

  position: absolute;
  top: 30px;
  right: 0;

  .lines {
    width: 46%;
    margin: 0 27%;
    height: 2px;
    background: #fff;
    border-radius: 3px;
    position: relative;
    transition: background-color 0.35s ease;

    &:after, &:before {
      position: absolute;
      height: 2px;
      background: #fff;
      border-radius: 4px;
      content: '';
      width: 100%;
      transition: transform 0.35s ease;
    }
    &:after {
      transform: translateY(-7px);
    }
    &:before {
      transform: translateY(7px);
    }
  }

  @include respond-down(M){
    display: flex;
  }
  @include respond-down(S){
    margin: 0 0 0 15px;

    width: 44px;
    height: 44px;
  }
}

.menu-phone-opener {
  background: $col-main;
  box-shadow: 0 4px 15px rgba(155, 126, 178, 0.74);
  right: 12.3%;
  display: none;

  @include respond-down(M){
    display: flex;
  }
  @include respond-down(S){
    right: 15%;
  }
  @include respond-down(XS){
    right: 80px;
  }

  svg {
    path {
      fill: #fff;
    }
  }
}

.menu-opened .menu-opener, .menu-opener.close {
  .lines {
    background: transparent !important;

    &:after {
      transform: rotate(45deg) translateY(0) !important;
    }
    &:before {
      transform: rotate(-45deg) translateY(0) !important;;
    }
  }
}