.mobile-menu {
  position: fixed;
  z-index: 400;
  left: 0;
  right: 0;
  opacity: 0;
  background: url("../images/mobile-menu-bg.jpg") no-repeat right center;
  background-size: auto 100%;
  top: -200%;
  bottom: 100%;
  transition: opacity 0.35s ease;

  .container-wrapper {
    min-height: 100vh;
    background: linear-gradient(to right, #9B7EB2 0%, #9A7EB1 50%, rgba(152, 124, 175, 0.85) 100%);

    @include respond-down(S) {
      background: linear-gradient(to right, #9B7EB2 0%, #9A7EB1 0%, rgba(152, 124, 175, 0.85) 100%);
    }
  }

  .menu-wrapper {
    display: block;

    .menu-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .logo-href {
        .logo-ny {
          display: block;
        }
        .logo-mobile {
          .figure, .text {
            fill: #fff;
          }
        }
      }
    }

    .nav-items {
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
      
      .item {
        font-size: 16px;
        color: #fff;
        font-weight: 500;
        padding: 16px 0;
        margin-left: 0;
        margin-right: 0;
        line-height: 16px !important;

        @include respond(XS){
          font-size: 14px;
          padding: 10px 0;
        }

        &.current {
          margin-left: 18px;
        }

        &:before {
          left: -12px;
          top: 20px !important;
          @include respond-down(XS) {
            top: 14px !important;
          }
        }
      }

      @include respond-down(M) {
      }
    }

    .phones {
      display: flex;
      position: relative;

      @include respond-down(XS){
        align-items: flex-start;
      }

      .button-attention {
        display: inline-block;

        @include respond-up(S){
          position: absolute;
          right: 0;
          top: 0;
        }
        @include respond-down(XS){
          //margin-top: 30px;
        }

      }

      @include respond-down(S){
        flex-direction: column;
      }

      .item {
        padding-left: 32px;
        position: relative;
        color: #fff;
        min-width: 240px;
        margin-bottom: 40px;

        @include respond(XS){
          margin-bottom: 20px;
        }

        svg {
          width: 22px;
          position: absolute;
          left: 0;
          top: 0px;
        }

        .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 2px;

          @include respond(XS){
            font-size: 14px;
          }
        }

        .data {
          font-size: 16px;
          letter-spacing: 0.03em;

          @include respond(XS){
            font-size: 12px;
          }

          .link {
            color: #fff;
            display: block;

            &.mail {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.menu-opened {
  overflow: hidden;
  position: fixed;

  .mobile-menu {
    opacity: 1;
    top: 0;
    bottom: 0;
    overflow: auto;
  }
}