.profile-page {

  .body {
    overflow: hidden;
    position: relative;

    .decorator-right {
      position: absolute;
      right: -280px;
      top: 1400px;

      @include respond-down(L) {
        width: 500px;
        right: -200px;
        top: 1400px;
      }

      @include respond-down(M) {
        display: none;
      }
    }

    .decorator-left {
      position: absolute;
      left: -370px;
      top: auto;
      bottom: 700px;

      @include respond-down(L) {
        width: 500px;
        left: -200px;
      }

      @include respond-down(M) {
        display: none;
      }
    }
  }

  .profile-block {
    padding-top: 10px;

    .degree-box {
      border-radius: 6px;
      background: $col-thin;
      padding: 37px 39px;
      margin-bottom: 20px;
      position: relative;

      @include respond-down(L){
        padding: 30px 31px;
      }

      @include respond-down(M){
        padding: 23px 26px;
        margin-bottom: 14px;
      }

      &.degree-full {
        @include respond-down(M){
          display: none;
        }
      }
      &.degree-box-mobile {
        display: none;

        @include respond-down(M){
          display: block;
        }
      }

      .label {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 1.35em;
        letter-spacing: 0.056em;
        color: $col-bold;
        margin-bottom: 26px;

        @include respond-down(L){
          font-size: 12px;
          margin-bottom: 10px;
        }
      }

      .value {
        color: $col-b0;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 8px;

        @include respond-down(L){
          font-size: 18px;
        }
      }

      .desc {
        color: $col-b0;
        font-size: 16px;
        margin-bottom: 26px;
        line-height: 1.35em;

        @include respond-down(L){
          font-size: 12px;
          margin-bottom: 16px;
        }

        @include respond-down(M){
          margin-bottom: 0;
        }

        @include respond(XS){
          margin-bottom: 16px;
        }
      }

      .button {
        display: inline-block;

        @include respond-down(M){
          position: absolute;
          top: 23px;
          right: 26px;
        }

        @include respond(XS){
          position: relative;
          top: auto;
          right: auto;
        }
      }
    }

    .header-part-left {
      flex: 100% 1 1;

      .header-part-left-pict {
        display: flex;
        align-items: flex-start;

        @include respond-down(S){
          flex-wrap: wrap;
          align-items: center;
          flex-direction: column;
        }
      }
    }

    .header-part {
      display: flex;
      align-items: flex-start;
      margin-bottom: 80px;

      @include respond-down(L){
        margin-bottom: 60px;
      }

      @include respond-down(M){
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      @include respond-down(S){
        margin-bottom: 4px;
      }

      @include respond(XS){
        justify-content: space-around;
      }

      .framed-photo {
        .photo {
          @include respond-property(width, 290px, 290px, 234px, 196px, 196px, 196px);
          @include respond-property(height, 390px, 390px, 314px, 263px, 263px, 263px);
        }
        @include respond-down(S){
          margin-bottom: 20px;
        }
      }

      .stars-wrapper {
        margin-bottom: 50px;

        @include respond-down(L){
          margin-bottom: 24px;
        }
        @include respond-down(S){
          text-align: center;
        }

        .stars-rate {
          margin-left: -3px;

          @include respond-down(S){
            justify-content: center;
          }

          .one-star svg {
            //max-width: 24px;

            @include respond-down(L){
              max-width: 16px;
            }
          }
        }

        .helper {
          font-size: 14px;
          color: $col-active;
          letter-spacing: 0.01em;
          font-weight: 500;

          @include respond-down(L){
            font-size: 12px;
          }
        }
      }

      .profile-desc {
        flex: 1 1 40%;
        padding: 6px 40px;

        @include respond-down(L){
          padding: 0 36px;
        }
        @include respond-down(M){
          padding: 0 30px;
        }
        @include respond-down(S){
          flex: 1 1 100%;
          text-align: center;
          padding: 0px;
        }
        @include respond(XS){
        }

        .role {
          display: none;
          color: $col-bold;
          line-height: 1.35em;
          font-size: 25px;
          margin-bottom: 14px;

          @include respond-down(M){
            display: inline-block;
          }

          @include respond-down(S){
            font-size: 20px;
          }
        }


        .meta-box {
          color: $col-bold;

          @include respond-down(M){
            display: flex;
            flex-wrap: wrap;
          }

          .box {
            margin-bottom: 24px;

            @include respond-down(L){
              margin-bottom: 18px;
            }

            @include respond-down(M){
              flex: 1 1 50%;
            }
            @include respond-down(S){
              flex: 1 1 100%;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .param {
              text-transform: uppercase;
              font-weight: bold;
              font-size: 14px;
              line-height: 1.35em;
              letter-spacing: 0.056em;
              margin-bottom: 8px;

              @include respond-down(L){
                font-size: 12px;
                margin-bottom: 6px;
              }
            }

            .value {
              font-size: 16px;
              line-height: 1.3em;
              letter-spacing: 0.01em;
              color: $col-b0;

              @include respond-down(L){
                font-size: 14px;
              }

              @include respond(XS){
                font-size: 12px;
              }
            }
          }
        }
      }

      .profile-meta {
        @include respond-property(flex-basis, 370px, 370px, 314px, 100%, 100%, 100%);
        flex-grow: 0;
        flex-shrink: 0;

        @include respond-down(M){
          margin-top: 30px;
        }

        .video-list {
          display: flex;
          align-items: center;

          @include respond-down(M){
            max-width: 320px;
          }
          @include respond(XS){
            width: 100%;
            max-width: 100%;
          }

          .item {
            width: 34%;
            margin-right: 4%;
            background: #ddd no-repeat center center;
            border-radius: 6px;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 20px 0;
          }

          .all-video {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.01em;
            line-height: 1.2em;
            color: $col-bold;

            @include respond-down(L){
              font-size: 14px;
            }

            svg {
              transition: transform 0.15s ease;
              transform: rotate(270deg) translateX(13px) translateY(0px);

              @include respond-down(L){
                transform: rotate(270deg) translateX(8px) translateY(0px);
              }

              path {
                fill: $col-bold;
              }
            }

            &:hover {
              svg {
                transform: rotate(270deg) translateX(13px) translateY(10px);

                @include respond-down(L){
                  transform: rotate(270deg) translateX(8px) translateY(10px);
                }
              }
            }
          }
        }
      }
    }

    .services-part {
      margin-bottom: 80px;
      display: flex;

      @include respond-down(L){
        margin-bottom: 60px;
      }
      @include respond-down(M){
        flex-wrap: wrap;
      }
      @include respond-down(S){
        margin-bottom: 30px;
      }

      .services-list {
        flex: 1 1 100%;
        @include respond-down(M){
          order: 2;
        }

        .sector-item {
          background: #FFF;
        }

        .button-wrapper {
          padding-top: 20px;
          display: flex;

          @include respond-down(M){
            justify-content: center;
          }
          @include respond-down(S){
            padding-top: 10px;
          }
        }
      }

      .services-text {
        @include respond-property(flex-basis, 370px, 370px, 314px, 100%, 100%, 100%);
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 35px 0 55px;
        letter-spacing: 0.01em;
        color: #414244;
        line-height: 1.55em;
        font-weight: 200;
        font-size: 17px;

        @include respond-down(L){
          padding: 0 25px 0 45px;
        }
        @include respond-down(M){
          order: 1;
          padding: 0 0 25px 0;
        }

        @include respond-down(S){
          font-size: 14px;
        }
      }

    }

    .header-write {
      margin-bottom: 60px;

      @include respond-down(L){
        margin-bottom: 40px;
      }
      @include respond-down(M){
        margin-bottom: 20px;
      }

      .write-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #414244;
        margin-bottom: 25px;

        @include respond-down(XS){
          flex-direction: column;
        }

        svg {
          margin-right: 10px;

          @include respond-down(XS){
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }

      .write-content {
        padding-top: 44px;
        @include respond-down(M) {
          padding-top: 30px;
        }

        .write-dropdown {
          margin-right: 180px;
          margin-bottom: 30px;

          @include respond-down(M) {
            margin-bottom: 18px;
          }

          @include respond-down(XS){
            flex: 0 0 100%;
            margin-right: 0;
          }
        }

        .button-and-phone {
          display: flex;

          @include respond-down(M){
            flex-wrap: wrap;
          }
          @include respond-down(XS){
            flex-direction: column;
            align-items: center;

          }

          .phone-option {
            position: relative;
            padding-left: 66px;
            color: #583671;
            margin-left: 40px;

            @include respond-down(XS) {
              margin-left: 0;
            }

            .icon {
              position: absolute;
              left: 0;
              display: inline-block;
              width: 48px;
              height: 48px;
              border-radius: 48px;

              svg {
                margin-left: -2px;
              }
            }

            .helper {
              font-size: 16px;
            }

            .p-phone {
              font-size: 20px;
              color: #583671;
              font-weight: bold;
              padding: 4px;
              margin: -4px;
            }
          }
        }
      }
    }

    .long-title {
      font-size: 30px;
      color: $col-bold;
      font-weight: 500;
      margin-bottom: 0.7em;
      line-height: 1.35em;

      &.xs-only {
        display: none;

        @include respond-down(XS) {
          display: block;
          text-align: center;
          padding-top: 20px;
          margin-bottom: 50px;
        }
      }

      @include respond-down(L) {
        font-size: 25px;
      }
    }

    .degree-boxes {
      .item {
        display: flex;
        margin-bottom: 90px;

        @include respond-down(M) {
          margin-bottom: 70px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        @include respond-down(XS) {
          flex-direction: column;
          margin-bottom: 16px;
        }

        .pre-header {
          flex: 0 0 308px;

          @include respond-down(L) {
            flex: 0 0 246px;
          }
          @include respond-down(M) {
            flex: 0 0 208px;
          }
          @include respond(XS) {
            flex: 0 0 auto;
          }

          .box {
            position: relative;
            margin: 22px auto ;
            background: $col-main;
            border-radius: 6px;
            padding: 70px 24px 34px 24px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-around;
            text-transform: uppercase;
            color: #FFF;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.06em;
            line-height: 1.55em;
            width: 100%;
            height: 145px;
            z-index: 10;

            @include respond-down(L) {
              height: 117px;
              margin: 24px auto ;
            }
            @include respond-down(S) {
              font-size: 17px;
            }
            @include respond-down(XS){
              margin: 0 0 17px 0;
            }

            .icon {
              width: 64px;
              height: 64px;
              background: $col-active;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              box-shadow: 0 4px 15px rgba(187,168,125,52);
              position: absolute;
              left: 50%;
              margin-left: -32px;
              top: -23px;

              @include respond-down(L) {
                width: 52px;
                height: 52px;
                margin-left: -26px;
              }

              svg {
                width: 50%;
              }
            }
          }

          .after-box {
            width: auto;
            font-size: 16px;
            color: $col-bold;
            border-top: 1px solid rgba(155, 126, 178, 0.40);
            padding-top: 15px;
            line-height: 1.55em;

            @include respond-down(L) {
              font-size: 16px;
            }
            @include respond-down(M) {
              font-size: 16px;
            }
            @include respond-down(S) {
              margin: 0 0 30px 0;
            }
            @include respond(XS){
              margin-bottom: 16px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: space-around;
            }
          }
        }

        .long-text {
          padding-left: 40px;
          padding-top: 10px;
          padding-right: 25%;

          @include respond-down(M) {
            padding-right: 0%;
          }
          @include respond(XS){
            padding-left: 0;
          }

          .long-title {
            @include respond-down(XS) {
              display: none;
            }
          }

          .text {
            font-size: 16px;
            color: $col-b0;
            line-height: 1.55em;

            @include respond-down(L) {
              font-size: 14px;
            }
            @include respond-down(M) {
              font-size: 12px;
            }

            p {
              margin-bottom: 1em;
            }

            ul {

              li {
                margin-bottom: 0.8em;
                padding-left: 30px;
                position: relative;
                line-height: 1.65em;

                @include respond-down(L) {
                  padding-left: 24px;
                }
                @include respond-down(M) {
                  padding-left: 20px;
                }

                &:before {
                  content: '';
                  position: absolute;
                  left: 0;
                  border: 2px solid $col-main;
                  border-radius: 10px;

                  @include respond-down(L) {
                    top: 5px;
                  }
                  @include respond-down(M) {
                    top: 4px;
                    width: 6px;
                    height: 6px;
                  }
                }
              }
            }

            .quote {
              padding-left: 23px;
              border-left: 2px solid $col-main;
              color: $col-bold;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .page-header {
    @include respond-down(S){
      margin-bottom: 0;
      padding-bottom: 0;
      flex-wrap: wrap;
    }

    .h1 {
      margin-bottom: 14px;

      @include respond(XS){
        order: 2;
        text-align: left;
        width: 100%;
        margin-bottom: 14px;

        span.F {
          text-transform: uppercase;
          display: block;
          font-size: 1.1em;
        }
      }
    }

    .h2 {
      font-family: Ubuntu, sans-serif;
      font-size: 30px;
      color: #583671;

      @include respond-down(L){
        font-size: 25px;
      }
      @include respond-down(S){
        display: none;
      }
    }

    .back-wrapper {
      display: flex;

      @include respond-down(S){
        order: 1;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0px;

        .rounded {
          br {
            display: none;
          }

          .arrow-back {
            width: 30px;
            height: 30px;

            svg {
              width: 15px;
            }
          }
        }
      }

      .profile-symbol {
        width: 80px;
        height: 80px;
        border: 2px solid $col-main;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        @include respond-down(L){
          width: 52px;
          height: 52px;
        }

        svg {
          width: 50%;
        }
      }
    }
  }
}

