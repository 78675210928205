.editor-panel {
  max-width: 380px;
  margin: 40px auto 150px auto;
  display: flex;
  align-items: center;
  border-radius: 9px;
  padding: 0 5px;
  box-shadow: inset 0 0 20px rgba(0,0,0,0.05), inset 0 1px 5px rgba(0,0,0,0.1);

  .add-block-button {
    flex: 1 1 100%;
    background: #FFF;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05), 0 1px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.25s ease;
    margin: 10px 5px;
    padding: 8px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    height: 72px;

    &:hover {
      box-shadow: 0 2px 10px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.3);
    }

    .text-lines {
      width: 100%;

      i {
        display: block;
        height: 3px;
        margin: 5px 0;
        border-radius: 4px;
        background: #eee;
        width: 100%;
      }
    }

    .e-cols-imgs {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .e-img {
        height: 58px;
        border-radius: 2px;
        background: #f2f2f2;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #999;
        font-weight: bold;
        font-family: monospace;
      }
    }

    .e-cols {
      width: 100%;
      display: flex;
      align-items: center;

      .e-img {
        width: 34px;
        height: 54px;
        background: #f2f2f2;
        flex: 0 0 auto;
      }
      .e-space {
        width: 16px;
      }

      &.e-offset {
        margin-top: 14px;
        border-top: 1px dashed rgba(0, 0, 0, 0.82);

        .e-img {
          margin-top: -16px;
          margin-left: 10px;
          height: 60px;
        }
      }
    }
  }
}

[data-editable-templates] {
  display: none;
}

[contenteditable=true]:empty:focus::before {
  content: ' Начните печатать.. ';
  white-space: nowrap;
  color: #070;
}
[contenteditable=true]:empty:not(:focus)::before {
  opacity: 0.5;
  content:attr(data-x);
}

[data-editable-content] {
  .editable-wrapper {
    transition: background 0.2s linear;
    background: transparent;
    position: relative;

    .pane {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      z-index: 300;
    }

    .remove, .draggable {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      opacity: 0;
      transition: opacity 0.2s linear;
      cursor: pointer;

      svg path {
        transition: fill 0.15s linear;
        fill: #191919
      }

      &:hover {
        opacity: 1;
        &.remove svg path { fill: #aa0000 }
        &.draggable svg path { fill: #00aa00 }
      }
    }

    &:hover {
      background: rgba(0,255,0,0.15);

      .remove, .draggable {
        opacity: 1;
      }
    }
  }



  .editable-input {
    background: rgba(255,255,255,0.8);
    padding: 3px;

    input {
      line-height: 17px;
      font-size: 14px;
      border: 1px solid rgba(0,0,0,0.3);
      padding: 2px 4px;
      display: block;
      min-width: 260px;
    }
  }

  .text-wrapper {
    padding: 30px 0;

    .text {
      strong {
        font-weight: 900;
      }
      em {
        font-style: italic;
      }
    }
  }

  .quoted-wrapper {
    padding: 30px 0;

    .quotes {
      .editable-input {
        z-index: 60;
        position: absolute;
        bottom: -50px;
      }
      .source {
      }
    }
  }

  .lead-wrapper {
    padding: 30px 0;

    .lead {
      font-size: 30px;
    }
  }

  .image-wrapper {
    padding: 30px 0;

    .image {
      margin-left: 0;
      margin-bottom: 25px;
      position: relative;

      img {
        display: block;
        width: 100%;
        transition: opacity 0.2s linear;
      }

      input[type="file"] {
        position: absolute;
        opacity: 0;
        z-index: 15;
        left: 0; right: 0; top: 0; bottom: 0;
        width: 100%;
      }
    }
  }

  .video-wrapper {
    padding: 30px 0;

    .video {
      position: relative;
      min-height: 31px;
    }

    iframe {
      background: #000;
      height: 640px;
      display: block;
    }
  }
}

.editor-block {
  .image, .image-large {
    background: #f8f8f8;
    box-shadow: inset 0 0 0 4px #e8e8e8;
    overflow: hidden;

    canvas {
      position: absolute;
      z-index: 40;
      left: 0;
      top: 0;
    }

    button {
      position: absolute;
      bottom: 10px;
      z-index: 50;

      &[data-img-load] {
        right: 10px;
      }
      &[data-img-remove] {
        left: 10px;
      }
    }

    .text-inputs {
      z-index: 20;
      position: absolute;
      left: 10px;
      top: 10px;
      right: 10px;

      input {
        line-height: 17px;
        font-size: 14px;
        border: 1px solid rgba(0,0,0,0.3);
        padding: 2px 4px;
        display: block;
        box-sizing: border-box;
        z-index: 20;
        background: #fff;
        margin-bottom: 3px;
        width: 100%;
        max-width: 300px;

        &:focus {
          border: 1px solid rgba(0,0,0,0.7);
        }
      }

    }

    input[data-name="title"],
    input[data-name="title_large"],
    input[data-name="url"],
    input[data-name="alt"],
    input[data-name="alt_large"]{
    }

    input[data-name="title"],
    input[data-name="title_large"] {
      top: 50px;
    }

    [type="file"] {
      opacity: 0;
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .image-placeholder {
      position: absolute;
      background-color: rgba(255,255,255,0.1);
      font-size: 13px;
      font-family: sans-serif;
      left: 0;
      right: 0;
      top:0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
