.news-item-page {
  .post-category {
    padding: 40px 0 30px 0;

    .badge {
      background: #F4EFFA;
      border-radius: 6px;
      color: #583671;
      font-weight: 500;
      font-size: 16px;
      padding: 10px 16px;

      @include respond-down(L){
        font-size: 14px;
      }
    }
  }

  .list-type-dot .item {
    margin-bottom: 0.3em;
    font-style: normal;
    &:before {
      top: 2px;
    }
  }

  .post-content {
    @include respond-down(M){
      margin-bottom: 40px;
    }
  }

  .separated {
    display: flex;
    align-items: start;
    margin-bottom: 90px;

    @include respond-down(L){
      margin-bottom: 60px;
    }
    @include respond-down(M){
      margin-bottom: 27px;
      flex-direction: column;
    }

    .data {
      flex: 0 0 50%;

      @include respond-down(M){
        order: 2;
      }

      &.full-size {
        flex: 0 0 100%;
      }

      &.offset {
        padding-left: 80px;
        max-width: 580px;

        @include respond-down(L){
          padding-left: 20px;
        }
        @include respond-down(M){
          display: none;
        }
      }

      .card-doctor-mobile {
        margin-bottom: 37px;

        @include respond-up(L){
          display: none;
        }
      }

      .card-doctor {
        width: 100%;
      }

      .attach {
        padding-top: 0.5em;
        font-weight: 500;
        font-size: 22px;
        color: #CFB986;
        margin-bottom: 26px;

        @include respond-down(L){
          font-size: 20px;
          margin-bottom: 21px;
        }
        @include respond-down(M){
          font-size: 18px;
        }
      }
    }
  }

  .post-title {
    font-size: 30px;
    margin-bottom: 10px;
    font-family: Ubuntu, sans-serif;
    line-height: 1.45em;

    @include respond-down(L){
      font-size: 25px;
      margin-bottom: 12px;
    }
  }

  .post-date {
    font-size: 14px;
    color: #B6A4C6;
    margin-bottom: 42px;

    @include respond-down(L){
      margin-bottom: 25px;
    }

    @include respond-down(S){
      font-size: 12px;
    }
    @include respond-down(XS){
      margin-bottom: 24px;
    }
  }

  .intro {
    margin-right: 15%;

    @include respond-down(L){
      margin-right: 20px;
    }
  }

  .text {
    font-size: 19px;
    line-height: 1.8em;
    color: #414244;

    @include respond-down(L){
      font-size: 17px;
    }

    strong, b {
      font-weight: bold;
    }

    a {
      color: #583671;
      text-decoration: underline;
      transition: color 0.25s ease;
      cursor: pointer;

      &:hover {
        color: rgba(#583671, 0.8);
      }
    }

    i {
      font-style: italic;
    }

    &.intro {
      font-weight: bold;
      font-size: 21px;
      //max-width: 100%;

      @include respond-down(L){
        font-size: 19px;
      }
      @include respond-down(M) {
        max-width: 100%;
      }
    }
  }

  .full-text {
    .text {
      max-width: 70%;

      @include respond-down(M) {
        max-width: 100%;
      }
    }
  }

  .editor-block {
    display: flex;
    @include respond-property('margin-bottom', 80px, 50px, 46px, 40px, 54px, 36px);

    @include respond-down(M) {
      width: auto;
      margin: auto 5.5%;
    }

    .image-container {
      width: 50%;
      padding-right: 50px;

      &.mobile {
        display: none;
        width: 100%;
        padding: 0;
      }

      @include respond-down(M) {
        display: none;

        &.mobile {
          display: block;
        }
      }

      .image {
        height: 0;
        width: 100%;
        margin-bottom: 25px;
        padding-bottom: 67%;

        img {
          width: 100%;
          top: 0;
          position: absolute;
        }

        @include respond-down(L){
          width: 100%;
          margin-bottom: 25px;
          margin-left: 0;
        }

        @include respond-down(XS){
          width: 100%;
          //height: 320px;
        }
      }

      .image-after {
        padding: 20px 30px;
        border-radius: 6px;
        background: #F4EFFA;
        max-width: 360px;

        @include respond-down(XL){
          max-width: 320px;
        }
        @include respond-down(M){
          max-width: 450px;
        }
        @include respond-down(S){
          text-align: center;
          max-width: 450px;
          margin: 0 auto 20px auto;
        }

        .h4 {
          font-weight: 500;
          font-size: 20px;
          color: #583671;
          margin-bottom: 10px;
          font-family: Ubuntu, sans-serif;

          @include respond-down(XL){
            font-size: 18px;
          }
          @include respond-down(XS){
            font-size: 16px;
          }
        }

        .after-text {
          font-size: 16px;
          color: #583671;

          @include respond-down(XS){
            font-size: 14px;
          }
        }
      }
    }

    .content-side {
      width: 580px;
      padding-left: 80px;

      @include respond-down(L){
        width: 460px;
        padding-left: 20px;
      }
      @include respond-down(M) {
        width: 100%;
        padding: 0;
      }
    }

    .h2 {
      font-size: 30px;
      font-weight: 500;
      color: #583671;
      margin-bottom: 54px;
      font-family: Ubuntu, sans-serif;
      line-height: 1.35em;

      @include respond-down(L){
        margin-bottom: 37px;
        font-size: 25px;
      }
      @include respond-down(M){
        margin-bottom: 25px;
      }
    }

    &.container {
      .image-container {
        margin: 0 0 0 160px;

        @include respond-down(L) {
          margin: 0 0 0 20px;
        }
        @include respond-down(M) {
          margin: 0 0 30px 0;
        }

        .image {
          @include respond-down(L) {
            width: 100%;
            margin: 0 0 25px 0;
            height: 0;
            padding-bottom: 67%;
          }
        }
      }

      .content-side {
        width: 50%;
        padding-left: 0;

        @include respond-down(L){
          flex: 0 0 50%;
          padding-right: 50px;
        }
        @include respond-down(M){
          flex: 1 1 100%;
          padding: 0px;
        }
      }
    }

  }
}

.call-to-action {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  text-align: center;

  @include respond-down(S) {
    padding: 0 24px;
  }

  .button {
    margin-bottom: 30px;
  }
  .helper {
    display: block;
    font-size: 20px;
    margin-bottom: 18px;
    color: #583671;
  }
  .p-phone {
    color: #583671;
    display: block;
    font-size: 22px;
    font-weight: bold;
    padding: 4px;
    margin: -4px;
  }
}

.editable-wrapper {
  overflow: hidden;

  @include respond-down(L){
    margin-right: 0;
    padding-right: 0;
  }

  @include respond-down(M){
  }

  .editor-block {
    @include respond-property('padding-bottom', 20px, 20px, 18px, 18px, 16px, 10px);
    @include respond-property('padding-top',    20px, 20px, 18px, 18px, 16px, 10px);
    position: relative;
    z-index: 10;
    min-height: 36px;

    .media {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #EEE no-repeat center center;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image, .image-large {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      user-select: none;
      overflow: hidden;

      margin-bottom: 13px;

      @include respond-down(M){
        margin-bottom: 10px;
      }

      iframe {
        background: #000;
        height: 460px;
        display: block;

        @include respond-down(XL){
          height: 420px;
        }
        @include respond-down(L){
          height: 390px;
        }
        @include respond-down(S){
          height: 280px;
        }
        @include respond-down(XS){
          height: 230px;
        }
      }
    }
  }
}
