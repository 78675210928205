.science-title {
  br {
    @include respond-down(M){
      display: none;
    }
  }
}

.science-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  padding-bottom: 150px;

  @include respond-down(L){
    padding-bottom: 100px;
  }

  .science-dir-wrapper {
    flex: 1 1 33.33%;
    padding: 12px;
    display: flex;

    @include respond-down(M){
      display: block;
      flex: 0 0 100%;
    }

    .science-dir {
      background: #F4EFFA;
      border-radius: 6px;
      padding: 32px 34px;
      position: relative;
      min-height: 286px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include respond-down(L){
        padding: 24px 24px;
      }
      @include respond-down(M){
        min-height: 0px;
      }

      .type {
        display: block;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.056em;
        text-transform: uppercase;
        color: #583671;
        @include respond-property(margin-bottom, 42px, 42px, 32px, 10px, 17px, 23px);
        @include respond-down(L){
          font-size: 12px;
        }

        .profile-link {
          font-size: 13px;
          color: #583671;
          display: block;
          text-transform: none;
          line-height: 1.35em;
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .icon-file {
        position: absolute;
        right: 24px;
        top: 30px;

        @include respond-down(L){
          right: 16px;
          top: 22px;
        }
        @include respond-down(M){
          right: 20px;
          top: 30px;
        }
      }

      svg.ppt-file {
        width: 38px;

        @include respond-down(L){
          width: 30px;
        }

        .figure {
          fill: #e87d42;
        }
      }
      svg.pdf-file {
        width: 32px;

        @include respond-down(L){
          width: 24px;
        }

        .figure {
          fill: #e84242;
        }
      }
      svg.doc-file {
        width: 32px;

        @include respond-down(L){
          width: 24px;
        }
      }

      .s-title {
        color: #414244;
        font-weight: bold;
        padding-right: 30%;
        line-height: 1.45em;

        @include respond-down(L){
          padding-right: 20%;
        }
        @include respond-down(M){
          padding-right: 0%;
        }

        @include respond-property(margin-bottom, 13px, 13px, 10px, 9px, 9px, 20px);
        @include respond-property(font-size, 18px, 18px, 16px, 18px, 18px, 16px);
      }

      .s-desc {
        color: #414244;
        font-size: 16px;
        line-height: 1.45em;
        @include respond-property(margin-bottom, 30px, 30px, 12px, 0px, 20px, 20px);
        @include respond-down(L){
          font-size: 12px;
        }
        @include respond(M){
          margin-right: 180px;
        }
      }

      .buttons {
        display: flex;
        align-items: center;

        .button {
          padding: 12px 50px 12px 80px;

          @include respond-down(L){
            padding: 12px 30px 12px 60px;
          }

          @include respond(M){
            position: absolute;
            right: 20px;
            bottom: 20px;
          }

          svg {
            left: 46px;

            @include respond-down(L){
              left: 26px;
            }

            .figure {
              fill: #9b7eb2;
              stroke: none !important;
            }
          }

          &:hover {
            svg {
              .figure {
                fill: #fff !important;
              }
            }
          }
        }

        .size {
          margin-left: 10px;
          font-weight: bold;
          font-size: 13px;
          letter-spacing: 0.056em;
          color: #9B7EB2;

          @include respond(M){
            display: none;
          }
        }
      }
    }
  }
}
