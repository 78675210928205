.about-page{

  .section-info .h1{
    @include respond-property(margin-bottom, 69px, 54px, 44px, 47px,  36px, 31px);
  }

  .excursion{
    @include respond-property(margin-top, 323px, 292px, 245px, 216px,  218px, 189px);
  }

  .body{
    position: relative;

    .decorator-right {
      position: absolute;
      right: -240px;
      top: 609px;
      z-index: 10;
      width: 784px;
      height: 937px;
      @include respond-down(XL) {
        display: none;
      }
    }

    .decorator-left {
      width: 1000px;
      position: absolute;
      left: -429px;
      top: 2366px;
      z-index: 10;
      height: 1123px;
      @include respond-down(XL) {
        display: none;
      }

    }
  }

  
}




.section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include respond-down(M){
    flex-direction: column;
    justify-content: flex-start;
  }


  .section-info{
    @include respond-property(width, 449px, 449px, 367px, 100%,100%,100%);
    flex-shrink: 0;
    // @include respond-down(M){
    //   padding-right: 32px;
    // }
    &__title{
      color: $col-bold;
      font-size: 30px;
      font-weight: 500;
      margin-top: 54px;
      
      @include respond-down(XL){
        font-size: 20px;
        margin-top: 0;
      }

      @include respond-property(margin-bottom, 53px, 44px, 30px, 30px,  24px, 24px);
     
    }

    .paragraph{
      line-height: 30px;
      color: $col-b0;
      font-size: 16px;
      @include respond-property(margin-bottom, 40px, 36px, 22px, 22px,  22px, 22px);
      &:last-child{
        margin-bottom: 0;
      }
      @include respond-down(XL){
        line-height: 27px;
        font-size: 14px;
      }

      .special{
        border-left: 2px solid $col-bold;
        padding-left: 12.8px;
        font-weight: 500;
        color: $col-bold;
        display: block;
        margin-bottom: 20px;
        @include respond-down(XL){
          margin-bottom: 13px;
        }
        @include respond-down(S){
          margin-bottom: 25px;
        }
        @include respond-down(XS){
          margin-bottom: 21px;
          max-width: 180px;
        }
      }
    }
  }
  .section-img-wrap{
    position: relative;
    flex-shrink: 0;
    width: 100%;

    @include respond-down(M){
      width: 460px;
      height: 310px;
    }
    @include respond-down(S){
      width: 310px;
      height: 210px;
    }
    @include respond-down(XS){
      width: 100%;
      height: auto;
    }
  }
  .section-img{
    width: 900px;
    height: 600px;
    position: relative;
    flex-shrink: 0;
    @include respond-property(width, 900px, 735px, 570px, 100%,100%,100%);
    @include respond-property(height, 600px, 490px, 380px, 310px,210px,auto);
    // @include respond-property(width, 900px, 735px, 570px, 460px,310px,100%);
    // @include respond-property(height, 600px, 490px, 380px, 310px,210px,auto);
      
    @include respond-down(M){
      margin-top: 47px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    @include respond-down(S){
      margin-top: 41px;
    }
    @include respond-down(XS){
      margin-top: 32px;
      flex-direction: column;
    }
    &--margin-right{
      margin-right: -285px;
      @include respond-property(margin-right, -285px, -130px, -100px, 0,0,0);
    }
    &--margin-left{
      @include respond-property(margin-left, -285px, -130px, -92px, 0,0,0);
    }


    &__desc{
      z-index: 1;
      position: absolute;
      bottom: -66px;
      right: calc(100% - 80px);
      background-color: $white;
      width: max-content;
      flex-shrink: 0;
      @include respond-down(XL){
        right: auto;
        left: -74px;
        bottom: -104px;
      }
      @include respond-down(L){
        left: -54px;
        bottom: -80px;
      }
      @include respond-down(M){
        position: static;
      }
      @include respond-down(XS){
        order: 1;
        margin-top: -22px;
      }

      
    }
    &__desc-inner{
      margin: 14px;
      @include respond-property(padding, 29px 26px, 20px 19px, 12px 14px, 12px 14px, 12px 14px, 12px 16px);
      background: linear-gradient(to right, $col-active, #B8A579);
      box-shadow: 0  3px  21px  #D0D2DF;

      @include respond-down(M){
        margin: 0;
      }
      @include respond-down(XS){
        width: 210px;
      }
    }

    &__logo{
     
      position: absolute;
      @include respond-property(top, 32px, 19px,16px,13.3px,10.9px,9.9px);
      @include respond-property(left, 43px, 26px,17px,13.7px,9.4px,8.4px);

      svg{
        fill: $white;
        @include respond-property(width, 107px, 89px, 66px, 53px, 46px, 41px);
        @include respond-property(height, 110px, 91px, 68px, 54px, 47px, 43px);
      }
    }
    &__text{
      line-height: 34px;
      font-size: 25px;
      color: $white;
      .num{
        font-size: 30px;
        font-weight: bold;
        display: block;
      }
      @include respond-down(XL){
        line-height: 23px;
        font-size: 18px;
        .num{
          font-size: 20px;
        }
      }
    }
    &__partner{
      font-size: 14px;
      color: $white;
      @include respond-property(margin-top, 27px, 24px, 20px, 20px,  20px, 12px);
      @include respond-property(margin-bottom, 32px, 23px, 13px, 13px,  13px, 12px);

      @include respond-down(XL){
        font-size: 12px;
      }
    }
    
    &__link{
      color: $white;
      text-decoration-line: underline;
      font-size: 18px;

      @include respond-down(XS){
        text-align: right;
        display: block;
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;

      
    }
  }
}

.section.services{
  @include respond-property(margin-top, 72px, 72px, 29px, 17px,  30px, 15px);
  // position: relative;

  // .decorator-left-icon{
  //   position: absolute;
  //   right: 0;bottom: 0;
  // }

}

.section.ideas{
  @include respond-property(margin-top, 205px, 205px, 148px, 47px,  41px, 35px);

  .section-img{
    @include respond-down(M){
      order: 1;
    }
    &__desc{
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;align-items: center;
      @include respond-property(width, 360px, 330px, 300px, 179px,  179px, 210px);
      @include respond-property(padding-top, 36px, 22px, 22px, 27px,  25px, 14px);
      @include respond-property(padding-bottom, 41px, 22px, 22px, 23px,  25px, 13px);
      // @include respond-property(height, 180px, 100px, 90px, 47px,  41px, 35px);

      @include respond-down(M){
        background: linear-gradient(to right, $col-active, #B8A579);
        box-shadow: 0  3px  21px  #D0D2DF;
      }
    }
    &__text{
      color: $col-active;
      @include respond-property(width, 230px, 249px, 221px, 129px,  129px, 162px);

      @include respond-down(M){
        color: $white;
      }
      @include respond-down(XS){
        text-align: center;
      }
    }
    
    .special{
      font-weight: bold;
    }
  }
  .section-info{
    @include respond-property(width, 460px, 449px, 400px, 100%,100%,100%);
  }
}

.section.employees{

  @include respond-property(margin-top, 159px, 121px, 112px, 52px,  62px, 55px);
  // align-items: center;
  .section-info{
    @include respond-property(width, 430px, 402px, 377px, 100%,100%,100%);

    &__title{
      margin-top: 0;
    }
  }

  .section-employees-images-wrap{
    // width: 100%;
    width: max-content;
    margin-top: 38px;
    @include respond-down(XS){
      margin-top: 56px;
    }
    @include respond-down(M){
      width: 100%;
      margin-top: 0;
    }
    .button{
      text-align: center;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
      @include respond-property(margin-top, 56px, 43px, 47px, 19px, 19px, 18px);
      display: block;
    }
  }
  .employees-images-list-wrapper{
    width: 100%;

    @include respond-down(M){
      overflow-x: auto;
    }

  }
  .employees-images-list{
    display: flex;
    align-items: center;
   

    @include respond-down(M){
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 22px;
      width: 100%;
      overflow-x: auto;
      width: max-content;
      padding-left: 22px;
      padding-right: 22px;
      margin-left: auto;
      margin-right: auto;
    }

    .employee-img{
      flex-shrink: 0;
      box-shadow: 0  0  21px  #D0D2DF;
      padding: 6px;
      background-color: $white;
      border-radius: 6px;
      @include respond-property(width, 229px, 200px, 150px, 189px, 189px,176px);
      @include respond-property(height, 299px, 261px, 196px, 263px, 263px,252px);
      @include respond-property(margin-right, 50px, 44px, 32px, 50px, 26px,26px);
      &:last-child{
        margin-right: 0;
      }

      img{
        border-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    
  }
}

.section.inventory{
  align-items: center;

  @include respond-property(margin-top, 102px, 104px, 93px, 68px,  62px, 59px);

  .section-info{
    @include respond-property(width, 472px, 466px, 382px, 100%,100%,100%);
  }
  .section-img-wrap{
    height: auto;
    width: 100%;
  }
  .section-img{
    @include respond-down(M){
      order: 1;
      width: 100%;
      height: auto;
      margin-top: 25px;
    }
    @include respond-down(S){
      margin-top: 41px;
    }
    @include respond-down(XS){
      margin-top: 24px;
    }
    
  }
}

.section.patients{
  @include respond-property(margin-top, 194px, 203px, 175px, 44px,  29px, 37px);
  
  .section-info{
    @include respond-property(width, 463px, 469px, 396px, 100%,100%,100%);
  }
  .section-img{
    &__text{
      @include respond-property(width, 218px, 199px, 178px, 134px,  134px, 185px);
    }
    &__logo{
      position: absolute;
      top: auto;
      @include respond-property(bottom, 32px, 19px,16px,13.3px,10.9px,9.9px);
      @include respond-property(left, 43px, 26px,17px,13.7px,9.4px,8.4px);
      @include respond-down(XS){
        bottom: auto;
        left: auto;
        top: 8.9px;
        right: 11.8px;
      }
      svg{
        fill: $white;
        @include respond-property(width, 107px, 89px, 66px, 53px, 46px, 41px);
        @include respond-property(height, 110px, 91px, 68px, 54px, 47px, 43px);
      }
      
    }
    
    &__desc{
      top: -50px;
      right: calc(100% - 110px);
      bottom: auto;
      left: auto;
      
      @include respond-property(line-height, 34px, 31px, 27px, 23px, 23px, 23px);
      

      @include respond-down(XL){
        top: auto;
        right: auto;

        bottom:calc(100% - 90px);
        left: -108px;
      }
      @include respond-down(L){
        bottom:calc(100% - 60px);
        left: -44px;
      }
      @include respond-down(M){
        position: static;
      }
      @include respond-down(XS){
        order: 1;
        margin-top: -22px;
      }
    }
    &__desc-inner{
      @include respond-property(padding, 36px 31px, 27px 25px, 18px 26px, 22px 23px, 22px 23px, 16px 12px);
    }
    &__text{
      @include respond-property(font-size, 20px, 18px, 16px, 18px, 18px, 16px);
      @include respond-down(XS){
        text-align: center;
      }
      .special{
        font-weight: 500;
        font-style: italic;
        .del{
          @include respond-down(M){
            display: none;
          }
          @include respond-down(XS){
            display: inline;
          }
        }
        
      }
    }
    
  }
}


