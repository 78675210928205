.attentions {
  .attention {
    background-color: #FCE8EB;
    padding: 0.2em 0.25em;
    margin: -0.2em -0.25em;
    border-radius: 3px;
  }

  &.green {
    .attention {
      background-color: #deffc3;
    }
  }

  &.yellow {
    .attention {
      background-color: #fbf4bb;
    }
  }
  &.blue {
    .attention {
      background-color: #9a79b4;
      color: #FFF;
    }
  }
}

.selector {
  border: 2px solid $col-main;
  color: $col-main;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: border 0.25s ease;
  -webkit-appearance: none;

  .arrow {
    position: absolute;
    right: 13px;
    height: 14px;
    margin-top: -7px;
    top: 50%;
    z-index: 10;
    transition: transform 0.25s ease;
  }

  .option {
    line-height: 20px;
    padding: 12px 20px;
    cursor: pointer;
    width: 100%;
    transition: background 0.15s ease;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: $col-bold;

    &.base {
      color: $col-b0;
      font-weight: 400;
    }

    @include respond-down(L){
      font-size: 14px;
    }
  }

  .selector-items {
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 12px #D4D6EB;
    opacity: 0;
    overflow: hidden;

    .option {
      @include respond-up(M){
        &:hover {
          background: rgba(214, 196, 236, 0.26);
        }
      }
    }
  }

  &.opened {
    overflow: visible;
    border-color: transparent;

    .arrow {
      transform: rotate(180deg);
    }

    .selector-items {
      opacity: 1;
    }
  }
}

.button {
  -webkit-appearance: none;
  user-select: none;
  background: transparent;
  border: 2px solid $col-main;
  color: $col-main;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.01em;
  text-align: center;
  border-radius: 5px;
  line-height: 20px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  position: relative;
  transition: background 0.15s ease, color 0.15s ease, box-shadow 0.30s ease;
  cursor: pointer;
  box-shadow: 0 11px 23px rgba(155, 126, 178, 0.0);

  @include respond-down(L){
    font-size: 14px;
    line-height: 18px;
    padding: 9px 18px;
  }

  @include respond-down(XS){
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  svg {
    position: absolute;
    left: 14px;
    top: 50%;
    margin-top: -12px;
    height: 24px;

    .figure {
      transition: fill 0.15s ease, stroke 0.15s ease;
      fill: $col-main;
      stroke: $col-main;
    }
  }

  &.button-icon {
    padding-left: 50px;
  }

  &.disabled {
    cursor: default !important;
    opacity: 0.7;
  }

  &.button-main {
    background: #9B7EB2;
    color: #FFF;
    box-shadow: 0 11px 23px rgba(154, 121, 180, 0.83);
  }

  &.caps {
    //text-transform: uppercase !important;
    font-weight: bold;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;

    @include respond-down(XS) {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
    }
  }

  &.button-attention {
    border: 2px solid $col-active;
    background: $col-active;
    color: #fff;
    box-shadow: 5px 5px 12px rgba(61, 76, 125, 0.13);

    svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  &.button-invert {
    border: 2px solid #fff;
    color: #fff;

    svg {
      .figure {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  @include respond-up(M){
    &:hover:not(.disabled) {
      box-shadow: 0 11px 23px rgba(155, 126, 178, 0.80);
      background: $col-main;
      color: #fff;

      svg {
        .figure {
          fill: #fff;
          stroke: #fff;
        }
      }

      &.button-attention {
        box-shadow: 5px 5px 12px rgba(61, 76, 125, 0.18);
        border: 2px solid #dfca94;
        background: #dfca94;
      }

      &.button-main {
        box-shadow: 0 5px 11px rgba(155, 126, 178, 0.80);
      }

      &.button-invert {
        box-shadow: 0 11px 23px rgba(255, 255, 255, 0.34);
        background: #fff;
        color: $col-main;

        svg {
          .figure {
            fill: $col-main;
            stroke: $col-main;
          }
        }
      }
    }
  }
}

.stars-rate {
  display: flex;

  &.disabled {
    cursor: default !important;

    .one-star {
      cursor: default !important;
    }
  }

  .one-star {
    position: relative;
    padding: 0 3px;
    cursor: pointer;

    // data-stars

    @include respond-down(L){
      margin-top: 0;
      margin-bottom: -5px;
    }

    svg.star {
      path {
        fill: #cfb986
      }
    }

    svg.star-empty {
      path {
        fill: #cfb986;
        stroke: #cfb986;
      }
    }

    svg {
      max-width: 18px;

      @include respond-down(L){
        max-width: 15px;
      }
    }

    .star, .star-empty {
      width: 20px;
    }

    .star {
      position: absolute;
      left: 3px;
      top: 2px;
      opacity: 0;
      transition: opacity 0.1s linear;

      @include respond-down(L){
        top: 4px;
      }
    }

    &:not(.disabled):hover, &.fill {
      .star {
        opacity: 1;
      }
    }
  }

  &:not(.disabled):hover, &.voted {
    .one-star {
      svg.star {
        path {
          fill: $col-main
        }
      }

      svg.star-empty {
        path {
          fill: $col-main;
          stroke: $col-main;
        }
      }
    }
  }

  &:not(.voted):hover {
  }
}

.form-control {
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  height: 42px;
  padding: 0 30px;
  font-size: 14px;
  letter-spacing: 0.01em;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 5px 12px #D4D6EB;
  transition: box-shadow 0.15s ease;

  @include respond-down(M){
    font-size: 12px;
    padding: 0 22px;
    margin: 0;
  }

  &:hover {
    box-shadow: 0 2px 10px #D4D6EB;
  }
  &:focus{
    box-shadow: 0 2px 10px #D4D6EB, inset 0 0px 0px 2px #D4D6EB;
  }
}

.subtitles {
  @include respond-property(font-size, 50px, 50px, 40px, 30px, 30px, 30px);
  font-family: "Cambria";
  color: $col-bold;
  font-weight: bold;
  line-height: 1.2em;
  margin-bottom: 90px;

  @include respond-down(L) {
    margin-bottom: 50px;
  }
}
