.profiles-list {
  border-top: 2px solid $col-main;
  padding-top: 44px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 90px;

  @include respond-down(L){
    margin-bottom: 60px;
  }

  @include respond-down(M){
    padding-top: 34px;
    margin-bottom: 40px;
  }

  @include respond(XS){
    border: none;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .profile-cart {
    margin-bottom: 34px;
    flex: 0 0 49.3%;
    border-radius: 6px;
    box-shadow: 0 0 21px #D0D2DF;
    padding: 17px;
    position: relative;

    @include respond-down(L){
      margin-bottom: 22px;
    }

    @include respond-down(M){
      flex: 0 0 100%;
      width: 100%;
      overflow: hidden;
    }

    @include respond(XS){
      border-radius: 0;
      box-shadow: 0 0 8px #D0D2DF;
      margin-bottom: 26px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .sub-cart-wrapper {
      display: none;
      background: $col-thin;

      @include respond(XS){
        display: block;
      }

      .arrow-wrapper {
        padding: 16px;
        cursor: pointer;

        svg {
          width: 12px;
          display: block;
          margin: auto;
          transition: transform 0.45s ease;
        }
      }

      .meta-box-wrapper {
        margin-left: 5.5%;
        margin-right: 5.5%;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.35s linear;

        .meta-box {
          opacity: 0;
          transition: opacity 0.25s ease;
          transition-delay: 0.3s;
          padding-bottom: 16px;
        }
      }

      &.opened {
        .meta-box-wrapper {
          max-height: 200px;

          .meta-box {
            opacity: 1;
            transition-delay: 0.0s;
          }
        }
        .arrow-wrapper svg {
          transform: rotate(180deg);
        }
      }
    }

    .cart-wrapper {
      display: flex;

      @include respond(XS){
        margin-left: 5.5%;
        margin-right: 5.5%;
        padding-bottom: 17px;
      }

      .photo {
        @include respond-property(width, 214px, 214px, 172px, 172px, 172px, 98px);
        @include respond-property(height, 286px, 286px, 230px, 230px, 230px, 132px);
        border-radius: 6px;
        overflow: hidden;
        background: #eee no-repeat center center;
        background-size: cover;
        flex-grow: 0;
        flex-shrink: 0;
        transition: box-shadow .15s ease;

        @include respond-up(L){
          &:hover {
            box-shadow: 0 0 0 2px $col-active;
          }
        }

      }

      .data {
        @include respond-property(padding-left, 26px, 26px, 14px, 21px, 24px, 10px);
        color: $col-bold;
        position: relative;
        flex: 1 1 75%;
        overflow: hidden;

        .meta-box {
          @include respond(XS) {
            display: none;
          }
        }

        .symbol {
          @include respond-property(width,  52px, 52px, 48px, 48px, 48px, 38px);
          @include respond-property(height, 52px, 52px, 48px, 48px, 48px, 38px);
          border: 2px solid $col-main;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          border-radius: 50px;
          right: 0;
          top: 0;

          @include respond(XS){
            right: 0;
            top: auto;
            bottom: 0;
          }

          svg {
            width: 50%;
          }
        }

        .read-more {
          display: none;
          position: absolute;
          right: 5px;
          bottom: 5px;
          font-size: 14px;
          font-weight: 500;
          color: $col-main;

          @include respond-down(M){
            display: block;
          }

          @include respond(XS){
            left: 10px;
            right: auto;
          }

          svg {
            transform: rotate(270deg);
          }
        }

        .name {
          @include respond-property(font-size, 22px, 22px, 16px, 20px, 18px, 16px);
          @include respond-property(margin-bottom, 14px, 14px, 20px, 15px, 15px, 13px);
          padding-top: 10px;
          padding-right: 70px;
          color: $col-bold;
          transition: color .15s ease;

          @include respond-up(L){
            &:hover {
              color: $col-active;
            }
          }

          @include respond-down(L){
            padding-top: 3px;
          }

          @include respond(XS){
            padding-right: 0;
          }
        }

        .position {
          @include respond-property(font-size, 20px, 20px, 16px, 18px, 18px, 14px);
          @include respond-property(margin-bottom, 32px, 32px, 22px, 22px, 22px, 18px);
          letter-spacing: 0.01em;
          font-weight: bold;
        }
      }
    }

    .meta-box {
      line-height: 1.35em;

      @include respond(M){
        display: flex;
        padding-bottom: 40px;
      }

      .box {
        @include respond-property(margin-bottom, 20px, 20px, 14px, 14px, 13px, 13px);
        @include respond(M){
          flex: 1 1 70%;
        }

        &:last-child {
          margin-bottom: 0;
          @include respond(M){
            flex: 1 1 30%;
          }
        }

        .param {
          @include respond-property(font-size, 13px, 13px, 12px, 12px, 12px, 10px);
          @include respond-property(margin-bottom, 8px, 8px, 0px, 0px, 0px, 2px);
          text-transform: uppercase;
          color: $col-main;
          font-weight: bold;
          letter-spacing: 0.056em;
          line-height: 1.4em;
        }

        .value {
          @include respond-property(font-size, 16px, 16px, 14px, 14px, 12px, 12px);
          padding-right: 15%;
          color: $col-b0;
          letter-spacing: 0.01em;
          line-height: 1.35em;

          @include respond-down(L){
            padding-right: 0%;
          }
        }
      }
    }

    .stars-wrapper {
      display: flex;
      padding-top: 14px;

      @include respond-down(L){
        margin-bottom: -5px;
      }

      @include respond(M){
        position: absolute;
        left: 207px;
        bottom: 20px;
        flex-wrap: wrap;
      }

      @include respond-down(S){
        display: none;
      }

      .stars-rate {
        @include respond-property(width, 214px, 214px, 172px, 172px, 172px, 98px);
        align-items: center;
        justify-content: center;

        @include respond(M){
          justify-content: flex-start;
          width: 100%;
        }
      }

      .helper {
        @include respond-property(padding-left, 26px, 26px, 14px, 3px, 24px, 10px);
        color: $col-active;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.01em;

        @include respond-down(L){
          font-size: 12px;
        }
      }
    }
  }
}
