@font-face {
    font-family: "Ubuntu";
    src: url("Ubuntu-Regular.ttf");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "Ubuntu";
    src: url("Ubuntu-Italic.ttf");
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "Ubuntu";
    src: url("Ubuntu-Medium.ttf");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "Ubuntu";
    src: url("Ubuntu-MediumItalic.ttf");
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: "Ubuntu";
    src: url("Ubuntu-Bold.ttf");
    font-style: normal;
    font-weight: bold;
}
