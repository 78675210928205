.news-page{

  .page-header{
    align-items: center;
    flex-wrap: wrap;
 
    .h1 {
      margin-right: 1em;
      margin-bottom: 14px;

      @include respond-down(S){
      }
    }
  }
}

.page-header--padding{
  @include respond-property(padding-top, 72px, 72px, 29px, 17px,  30px, 15px);
  @include respond-property(padding-bottom, 105px, 90px, 43px, 47px,  38px, 34px);
}
.nav-filter{
  @include respond-down(S){
    width: 100%;
  }

  &__item{
    font-size: 16px;
    margin-right: 16px;
    border: 2px solid $col-thin;
    border-radius: 6px;
    transition: background-color 0.25s ease;
    padding: 10px 11px;
    font-weight: 500;
    line-height: 3em;
    
    @include respond-down(L){
      font-size: 14px;
    }
    @include respond-down(M){
      margin-right: 13px;
      padding: 8px;
    }
    @include respond-down(XS){
      margin-bottom: 10px;
    }
    
    &:last-child{
      margin-right: 0;
    }
    &--active, &:hover{
      background-color: $col-thin;
    }
  }
}

.news-load-more {
  display: block;
  margin: -50px auto 50px auto;
}

.news-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.06%;
  margin-right: -1.06%;
  @include respond-property(margin-bottom, 125px, 75px, 75px, 75px,  80px, 65px);
  @include respond-down(M){
    margin-left: -1.62%;
    margin-right: -1.62%;
  }
  @include respond-down(S){
    margin-left: 0;
    margin-right: 0;
  }
  .news-list-item{
    position: relative;
    // height: max-content;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    width: 31.2%;
    margin-left: 1.06%;
    margin-right: 1.06%;
    color: $col-b0;
    transition: box-shadow 0.25s;
    box-shadow: 0 10px 13px $col-shadow-news-item;
    @include respond-property(padding-bottom, 32px, 22px, 20px, 0,  0,  0);
    @include respond-property(margin-bottom, 58px, 51px, 24.8px, 0, 0, 0);
    @include respond-down(M){
      margin-left: 1.62%;
      margin-right: 1.62%;
      width: 46.76%;
      box-shadow:none;
      overflow: visible;
      &::after{
        content: '';
        display: block;
        height: 1px;
        width: 130%;
        background-color: $col-line;
        position: absolute;
        // bottom: 30px;
        bottom: 5%;
      }
      &:nth-child(odd) {
        &::after{
          left: 0;
        }
      }
      &:nth-child(even) {
        &::after{
          right: 0;
        }
      }
      &:nth-child(5), &:nth-child(6){
        &::after{
          display: none;
        }
      }
    }
    @include respond-down(S){
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      height: max-content;
      &::after{
        display: none;
      }
      .hr{
        display: block;
      }
      &:last-child{
        .hr{
          display: none;
        }
      }
    }
    
    

    &:hover{
      box-shadow: 0 0 30px $col-shadow-news-item-active;

      @include respond-down(M){
        box-shadow:none
      }
    }
    &__info{
      @include respond-property(padding, 16px 28px 0, 16px 24px 0, 16px 20px 0, 18px 0 0, 0, 0);
    }

    &__info-top{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__class{
      font-size: 16px;
      border: 2px solid $col-thin;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      background-color: $col-thin;
      color: $col-bold;
      flex-shrink: 0;
      flex-grow: 0;
      @include respond-property(font-size, 16px, 14px, 12px, 14px, 14px, 14px);
      @include respond-property(width, 130px, 125px, 104px, 125px, 125px, 125px);
      @include respond-property(height, 40px, 35px, 30px, 35px, 35px, 35px);

      @include respond-down(S){
        position: absolute;
        top: 18px;
        right: 15px;
      }
      @include respond-down(XS){
        position: absolute;
        top: 8px;
        right: 11px;
      }
    }
    &__date{
      color: $col-line;
      font-size: 14px;
      @include respond-down(L){
        font-size: 12px;
      }
      @include respond-down(S){
        display: none;
      }
    }
    &__img{
      // height: 310px;
      // height: 58%;
      flex-shrink: 0;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
      padding-bottom: 70%;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      @include respond-down(S){
        // height: 350px;
        padding-bottom: 66.5%;
      }
      @include respond-down(XS){
        padding-bottom: 70%;
      }
    }

    &__title{
      @include respond-property(margin-top, 20px, 15px, 14px, 20px, 20px, 18px);

      a{
        color: $col-b0 ;
        margin-top: 20px;
        transition: all 0.25s ease;
        font-weight: 500;
        cursor: pointer;
        line-height: 1.35em;
        @include respond-property(font-size, 22px, 20px, 16px, 16px, 18px, 18px);

        &:hover{
          color: $col-active;
        }
      }
      
    }

    &__desc{
      color: $col-b0 ;

      @include respond-property(margin-top, 18px, 14px, 14px, 9px, 9px, 9px);

      @include respond-property(font-size, 16px, 14px, 12px, 12px, 14px, 14px);

      @include respond-down(M){
        margin-bottom: 10%;
      }
      @include respond-down(S){
        margin-bottom: 0;
      }
      @include respond-down(XS){
        padding-right: 36px;
      }
      .answer{
        color: $col-main;
        font-weight: bold;
        margin-right: 4px;
      }
    }
  }

  .hr{
    display: none;
    height: 1px;
    width: 100%;
    background-color:  $col-line;
    margin: 24px 0 36px;

    @include respond-down(XS){
      margin: 19px 0;
    }
  }
}
