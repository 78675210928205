
.directions-page {
  .by-phone-block {
    height: 102px;
    background: linear-gradient(to right, #9A79B4, #614985);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 33px;
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 10px;

    @include respond-down(M){
      height: 86px;
      margin-bottom: 8px;
    }

    @include respond-down(S){
      height: 72px;
      padding: 0 14px;
    }

    @include respond(XS){
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 144px;
      padding: 20px 16px;
    }

    .text {
      font-size: 20px;
      letter-spacing: 0.01em;
      font-weight: 500;
      color: #fff;
      z-index: 10;

      br {
        display: none;

        @include respond(XS){
          display: block;
        }
      }

      @include respond-down(M){
        font-size: 16px;
      }

      @include respond(XS){
        margin-bottom: 16px;
      }
    }

    .button {
      font-size: 16px;
      font-weight: 500;
      z-index: 10;
      flex: 0 0 auto;
    }

    .lines {
      z-index: 1;
      position: absolute;
      left: 30%;
      width: 40%;
      top: 0;
      bottom: 0;
      height: 100%;

      @include respond(S){
        display: none;
      }
      @include respond(XS){
        left: 50%;
        width: 90%;
      }
    }
  }

  .export-lab {
    background: linear-gradient(to right, #CFB986, #B8A579);
    padding: 0 24px 0 33px;
    border-radius: 6px;
    height: 70px;
    color: #fff;
    display: flex;
    align-items: center;

    @include respond-down(L){
      padding: 0 16px 0 16px;
    }
    @include respond-down(M){
      padding-bottom: 12px;
      padding-top: 12px;
      height: auto;
    }
    @include respond-down(XS){
      flex-wrap: wrap;
      padding: 12px 12px;
    }

    .count {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include respond-down(XS){
        display: none;
      }

      .c {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 0.01em;

        @include respond-down(L){
          font-size: 20px;
        }
      }
      .helper {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .text {
      flex: 1 1 100%;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 14px;
      letter-spacing: 0.01em;
      line-height: 1.5em;

      @include respond-down(L){
        padding-left: 16px;
        padding-right: 16px;
        font-size: 12px;
      }
      @include respond-down(XS){
        padding-left: 0;
      }
    }

    .href {
      flex: 0 0 auto;
      position: relative;
      font-size: 18px;
      font-weight: 500;
      text-decoration: underline;
      color: #fff;
      padding-right: 30px;
      margin-right: 10px;

      @include respond-down(L){
        font-size: 16px;
      }
      @include respond-down(XS){
        flex: 0 0 100%;
        text-align: right;
        padding-right: 45px;
      }

      svg {
        position: absolute;
        top: 2px;
        right: -5px;

        @include respond-down(XS){
          right: 10px;
        }
      }
    }
  }

  .page-header {
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: space-between;
    padding-top: 58px;
    padding-bottom: 42px;
    @include respond-down(L){
      padding-top: 42px;
      padding-bottom: 37px;
    }
    
    @include respond-down(M){
      // padding-top: 20px;
      padding-bottom: 30px;
    }
    @include respond-down(S){
      // +!
      // flex-wrap: nowrap;
      padding-bottom: 22px;
    }

    @include respond(XS){
      padding-top: 26px;
      padding-bottom: 22px;
    }

    .h1 {
      flex: 0 0 39%;
      font-size: 50px;
      
      &.service-title{
        margin-left: 32px;
        margin-bottom: 0;
        
        @include respond-down(L){
          margin-left: 27px;
        }
        @include respond-down(M){
          font-size: 40px;
        }
        @include respond-down(S){
          font-size: 30px;
          margin-left: 15px;
        }
        @include respond-down(XS){
          font-size: 25px;
          margin-left: 12px;
        }
      }

      br {
        @include respond(S){
          display: none;
        }
      }
      @include respond-down(L){
        // +!
        //flex: 0 0 100%;
        margin-bottom: 20px;
      }
    }
    
    .service{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .service-icon{
      border: 2px solid $col-main;
      border-radius: 50%;
      width: 84px;
      height: 84px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      @include respond-down(M){
        width: 70px;
        height: 70px;
      }
      @include respond-down(S){
        width: 55px;
        height: 55px;
      }
      @include respond-down(XS){
        width: 51px;
        height: 51px;
      }
        svg{
        width: 44px;
        height: 40px;

        @include respond-down(M){
          width: 36px;
          height: 33px;
        }
        @include respond-down(S){
          width: 29px;
          height: 26px;
        }
      }
    }
    
    .come-back{
      display: block;
      cursor: pointer;

      span {
        display: block;
      }
      
      &:hover{
        .come-back__text{
          color: $col-active;
        }
        & svg{
          fill: $col-active;
        }
      }
      @include respond-down(XS){
        display: none;
      }

      &__text{
        display: block;
        color: $col-bold;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        transition: all 0.25s;
        @include respond-down(M){
          font-size: 12px;
          line-height: 1;
        }
      }
      & svg{
        fill: $col-bold;
        transition: all 0.25s;
        @include respond-down(M){
          width: 20px;
        }
      }
    }
    .search-wrapper {
      padding-top: 20px;
      flex: 1 1 70%;

      @include respond-down(L){
        padding-top: 0;
      }

      .search-title {
        display: flex;
        align-items: center;
        color: $col-b0;
        font-size: 20px;
        margin-bottom: 26px;

        @include respond-down(L){
          font-size: 18px;
        }

        @include respond-down(M){
          display: none;
        }

        svg {
          margin-right: 18px;

        }
      }

      .input-wrapper {
        position: relative;

        .form-control {
          margin-right: 30px;
          position: relative;
          z-index: 220;
        }

        .results {
          position: absolute;
          background: #fff;
          left: 0;
          top: 0;
          right: 0;
          width: 100%;
          border-radius: 6px;
          box-shadow: 0 5px 12px #D4D6EB;
          z-index: 200;
          padding-top: 42px;
          opacity: 0;
          max-height: 0;
          overflow: hidden;
          transition: opacity 0.25s ease, max-height 0.25s ease;

          .result {
            height: 47px;
            line-height: 1.3em;
            padding: 0 30px;
            font-size: 14px;
            display: flex;
            align-items: center;
            font-weight: bold;
            transition: background-color 0.15s ease;

            @include respond-down(M){
              font-size: 12px;
              padding: 0 22px;
            }

            @include respond-up(M){
              &:hover {
                cursor: pointer;
                background: rgba(214, 196, 236, 0.26);
              }
            }
          }
        }

        &.process {
          .form-control {
            box-shadow: none;
          }

          .results {
            opacity: 1;
            max-height: 40px * 6 + 42px;
          }
        }

        .search-button {
          padding-right: 14px;
          background: transparent;
          border: none;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          cursor: pointer;

          svg .figure {
            fill: #d8d8d8;
            transition: fill 0.15s ease;
          }

          &:hover {
            svg .figure {
              fill: #989898
            }
          }

          &:active {
            svg .figure {
              fill: #777
            }
          }
        }
      }
    }
  }
  .page-about{
    display: flex;
    justify-content: space-between;
    margin-bottom: 84px;

    @include respond-down(L){
      margin-bottom: 80px;
    }
    @include respond-down(M){
      margin-bottom: 66px;
      flex-direction: column;
    }
    @include respond-down(S){
      margin-bottom: 52px;
    }
    @include respond-down(XS){
      margin-bottom: 40px;
    }

    &__image{
      flex-shrink:1;
      // width: 683px;
      // width: 48.7%;
      // @include respond-down(L){
      //   width: 575px;
      // }
      @include respond-down(M){
        width: 100%;
      }

      img{
        width: 100%;
        // height: 100%;
        object-fit: contain;
        border-radius: 7px;
        overflow: hidden;
      }
    }

    &__info{
      flex-shrink:1;
      margin-left: 54px;
      margin-top: 14px;
      max-width: 610px;
      @include respond-down(L){
        margin-top: 0;
        margin-left: 32px;
      }

      @include respond-down(M){
        margin-top: 52px;
        margin-left: 0;
        max-width: 100%;
      }
      @include respond-down(XS){
        margin-top: 40px;
      }
    }

    &__info-desc{
      font-size: 16px;
      margin-bottom: 48px;
      color: $col-b0;
      line-height: 28px;
      @include respond-down(L){
        margin-bottom: 42px;
      }
      @include respond-down(M){
        font-size: 14px;
        margin-bottom: 22px;
      }
      @include respond-down(S){
        margin-bottom: 30px;
      }

      &:last-child{
        margin-bottom: 0;
      }

      &.primary {
        color: $col-bold;
        padding-left: 20px;
        position: relative;
        margin-top: -2px;
        font-weight: 500;
        border-left: 2px solid $col-main;

        
        @include respond-down(M){
          padding-left: 16px;
        }
        // &::before{
        //   top: 6px;
        //   content: '';
        //   width: 2px;
        //   height: 90%;
        //   background-color: $col-main;
        //   position: absolute;
        //   left: 0;
          
        // }
      }
    }
  }

  .d-block {
    display: flex;
    margin-bottom: 100px;

    @include respond-down(M){
      margin-bottom: 30px;
    }

    .d-menu {
      border-right: 1px solid rgba(112, 112, 112, 0.17);
      border-top: 1px solid rgba(112, 112, 112, 0.17);
      flex: 0 0 22.4%;
      width: 22.4%;

      @include respond-down(M){
        display: none;
      }

      .dir-item {
        .title {
          height: 60px;
          display: flex;
          align-items: center;
          padding: 0 20px;
          color: $col-bold;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.3em;
          transition: color 0.15s ease, background 0.15s ease;

          & > svg {
            transition: transform 0.25s ease;
            width: 14px;
            flex: 0 0 14px;

            .figure {
              transition: fill 0.15s ease;
              fill: $col-bold;
            }
          }

          .text {
            flex: 1 1 90%;
            padding-bottom: 3px;
          }

          .icon {
            margin-right: 18px;
            padding-top: 3px;

            svg {
              width: 26px;
              max-height: 30px;
              transition: fill 0.15s ease;
            }
          }

          &:hover {
            background: $col-thin;
            cursor: pointer;
          }
        }

        &.selected {
          border-bottom: 1px solid rgba(112, 112, 112, 0.17);
          .title {
            background: $col-main;
            color: #fff;
            box-shadow: 0 5px 12px #D4D6EB;

            & > svg {
              transform: rotate(180deg);
              .figure {
                fill: #D6C4EC;
              }
            }
          }

          .items-list {
            max-height: 800px;
            transition: max-height 0.25s ease-in;

            .service-main {
              float: right;
              padding: 4px 8px;
              font-size: 14px;
              color: #9d9d9d;
              transition: color 0.25s ease;
              text-decoration: underline;
              margin: 10px;

              @include respond-up(L){
                &:hover {
                  color: #CFB986;
                }
              }
            }
          }
        }

        .items-list {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.15s ease-out;

          .items-list-wrapper {
            padding: 16px 0;

            .item {
              position: relative;
              display: flex;
              align-items: center;
              font-weight: 500;
              color: $col-bold;
              padding: 6px 20px 6px 48px;
              transition: color 0.25s ease;
              cursor: pointer;
              font-size: 16px;

              &:after {
                width: 8px;
                height: 8px;
                border-radius: 8px;
                position: absolute;
                left: 18px;
                top: 15px;
                content: '';
                background: #D6C4EC;
                transition: background 0.25s ease;
              }

              &:before {
                position: absolute;
                left: 7px;
                top: 4px;
                width: 26px;
                height: 26px;
                content: '';
                border-radius: 30px;
                border: 2px solid $col-active;
                transition: transform 0.35s ease;
                transform: scale(0.2);
              }

              &:hover, &.selected {
                color: $col-active;

                &:after {
                  background: $col-active;
                }
              }

              &.selected {
                &:before {
                  transform: scale(1);
                }
              }
            }
          }
        }
      }
    }

    .d-content {
      flex: 1 1 90%;

      @include respond-down(M){
        flex: 0 0 100%;
      }

      .sub-headed {
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 61px;

        @include respond-down(M){
          display: none;
        }

        .text {
          font-size: 20px;
          color: $col-bold;
          font-weight: bold;
        }
      }

      .direction-content {
        display: none;
        opacity: 0;
        animation: fadex 0.25s ease;
        z-index: 10;

        @keyframes fadex {
          0%   { display:none;  opacity: 0; }
          0.1% { display:block; opacity: 0; }
          0%   { display:block; opacity: 1; }
        }

        &.selected {
          display: block;
          opacity: 1;
          z-index: 20;
        }
      }

      .scroll-area-wrapper {
        position: relative;

        .line {
          position: absolute;
          left: 0;
          top: 0;
          right: 26px;
          border-top: 1px solid rgba(112, 112, 112, 0.17);

          @include respond-down(M){
            display: none;
          }
        }

        &:before {
          content: '';
          position: absolute;
          height: 146px;
          right: 6px;
          left: 0;
          top: 0;
          background: linear-gradient(to bottom, #fff 0%, #fff 83%, rgba(255,255,255,0.0) 100%);

          @include respond-down(M){
            display: none;
          }
        }

        &:after {
          content: '';
          position: absolute;
          height: 40px;
          right: 6px;
          left: 0;
          bottom: 0;
          background: linear-gradient(to top, #fff, rgba(255,255,255,0.0));
        }

        &.dir-4 {
          &:before {
            @include respond-up(L){
              height: 246px;
            }
          }
        }
      }

      .scroll-area {
        max-height: 800px;
        overflow: auto;
        padding: 14px + 112px 20px 14px 20px;

        &.dir-4 {
          @include respond-up(L){
            padding-top: 14px + 112px + 70px + 10px;
          }
        }

        @include respond-down(M){
          max-height: unset;
          padding: 10px 1px 10px 1px;
        }

        .sector-title {
          border-left: 2px solid $col-active;
          padding-left: 10px;
          color: $col-active;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 22px;
          margin-top: 24px;

          @include respond-down(L){
            font-size: 16px;
          }

          @include respond-down(M){
            margin-left: 7px;
          }

          @include respond(XS){
            margin-bottom: 17px;
            margin-top: 10px;
            margin-left: 0;
          }

          .service-main {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #9d9d9d;
            transition: color 0.25s ease;
            margin: 0 10px;
            text-decoration: underline;

            @include respond-up(L){
              &:hover {
                color: #CFB986;
              }
            }
          }
        }

        .by-phone-block {
          position: absolute;
          left: 20px;
          right: 26px;
          top: 14px;

          @include respond-down(M){
            display: none;
          }
        }

        .export-lab {
          position: absolute;
          left: 20px;
          right: 26px;
          top: 14px + 102px + 10px;

          @include respond-down(M){
            display: none;
          }
        }
      }
    }
  }
}

.sector-item {
  padding: 23px 30px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px #E7E7E7;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  transition: box-shadow 0.25s ease, background-color 4.00s ease;
  background: transparent;

  &.attention {
    background: $col-active;
    transition: box-shadow 0.25s ease, background-color 0.20s ease;
  }

  @include respond-up(L){
    &:hover {
      box-shadow: 0 5px 12px #D4D6EB;
    }
  }

  @include respond(XS){
    padding: 13px 16px;
    flex-wrap: wrap;
  }

  .name {
    flex: 1 1 98%;
    font-size: 16px;
    color: $col-b0;
    line-height: 1.35em;

    @include respond-down(M){
      //font-size: 14px;
    }
    @include respond(XS){
      //font-size: 12px;
    }

    .main {
      font-weight: bold;
      display: block;
    }

    .helper {
      padding-top: 3px;
      display: block;
    }
  }

  .price {
    flex: 0 0 auto;
    color: $col-bold;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;

    @include respond(XS){
      flex: 0 0 100%;
      text-align: right;
      padding-top: 10px;
    }
  }
}

.selectors {
  display: flex;

  .checkbox {
    position: relative;
    padding-left: 20px;
    color: $col-bold;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.35em;
    padding-top: 2px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include respond(XS){
      font-size: 12px;
      margin-right: 0;
    }


    svg {
      height: 22px;
      margin-left: 5px;
    }

    &:before, &:after {
      left: 0;
      top: 7px;
      width: 14px;
      height: 14px;
      background: $col-thin;
      border-radius: 15px;
      content: '';
      z-index: 5;
      position: absolute;
      transition: all  0.15s linear;
    }

    &.selected {
      &:after {
        background: $col-bold;
        transform: scale(0.5);
      }
    }
  }
}

.quotable-item {
  color: $col-bold;
  padding-left: 20px;
  position: relative;
  margin-top: -2px;
  font-weight: 500;
  border-left: 2px solid $col-main;
  @include respond-down(M){
    padding-left: 16px;
  }
}
