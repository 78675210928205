.policy-page {
  text-align: left;
  padding-bottom: 50px;

  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    padding-top: 0;
    padding-bottom: 0;
    text-indent: 35.5pt;
  }
  .pre-head {
    color: #000000;
    font-weight: 700;
    margin-bottom: 0.8em;
  }
  .p2 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal;
  }
  .left {
    text-indent: 0;
  }

  .payment {
    max-width: 500px;
    margin-bottom: 10px;
  }


}