.service-page{
  .slick-disabled{
    opacity: 0.26;
  }

  .slider-btns{
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    @include respond-down(L){
      position: static;
      transform: none;
      margin-top: 14px;
      width: 86px;
    }
    @include respond-down(M){
      margin-top: 9px;
      width: 133px;
    }
    @include respond-down(S){
      width: 140px;
      margin-top: 5px;
    }
    @include respond-down(XS){
      width: 106px;
      margin-top: 1px;
      margin-right: auto;
    }

    .slick-next, .slick-prev{
      width: 31px;
      height: 31px;
      display: flex;
      justify-content: center;align-items: center;
      background-color: $col-active;
      border: none;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      @include respond-down(L){
        position: static;
      }
      @include respond-down(M){
        width: 41px;
        height: 41px;
      }
      @include respond-down(S){
        width: 47px;
        height: 47px;
      }
      @include respond-down(XS){
        width: 41px;
        height: 41px;
      }
      svg{
        fill: $white;
        width: 11px;
        height: 11px;
        @include respond-down(M){
          width: 16px;
          height: 16px;
        }
        @include respond-down(XS){
          width: 14px;
          height: 14px;
        }
      }
    }
    .slick-next{
      left: 100%;
      svg{
        margin-left: 2px;
      }
    }
    .slick-prev{
      right: 100%;
      svg{
        transform: rotate(-180deg);
        margin-right: 2px;
      }
    }
  }


  .card-doctors-list{
    max-width: 470px;
    // padding: 15px;
    @include respond-down(M){
      max-width:100%;
      width: 100%;
    }

    &__slide{
      padding: 15px;

      @include respond-down(M){
        padding: 15px 5.5%;
      }
      @include respond-down(XS){
        padding: 15px 0;
      }
    }
  }

  .service-header{
    display: flex;
    justify-content: space-between;
    @include respond-property(margin-top, 78px, 78px, 51px, 43px, 24px,  30px);
    @include respond-down(M){
      flex-direction: column;
    }
    .article{

      &__title{

        @include respond-property(margin-bottom, 41px, 41px, 32px, 43px, 44px,  28px);
        line-height: 44px;
        margin-top: 0;

        @include respond-down(L){
          line-height: 37px;
        }
        @include respond-down(S){
          line-height: 30px;
          font-size: 20px;
        }
      }
    }
  }

  .article{
    margin-right: 550px;

    @include respond-down(L){
      margin-right: 500px;
    }
    @include respond-down(M){
      width: 100%;
    }

    &__title{
      color: $col-bold;
      font-size: 30px;
      font-weight: 500;
      @include respond-property(margin-bottom, 41px, 41px, 44px, 44px, 39px, 37px);
      @include respond-property(margin-top, 51px, 51px, 54px, 54px, 49px, 47px);

      @include respond-down(L){
        font-size: 25px;
      }
    }


    .paragraph{
      line-height: 30px;
      color: $col-b0;
      font-size: 16px;
      @include respond-property(margin-bottom, 40px, 36px, 22px, 22px,  22px, 22px);

      &:last-child{
        margin-bottom: 0;
      }

      @include respond-down(L){
        line-height: 27px;
        font-size: 16px;
      }

      @include respond-down(S){
        line-height: 27px;
        font-size: 14px;
      }

      .link{
        font-weight: 500;
        color: $col-bold;
        text-decoration-line: underline;
        transition: color 0.25s ease;
        &:hover{
          color: $col-active;
        }
      }
      .special{
        border-left: 2px solid $col-bold;
        @include respond-property(padding-left, 20px, 20px, 22px, 20px, 20px, 18px);
        font-weight: 500;
        color: $col-bold;
        display: block;
        margin-top: 36px;
        @include respond-down(L){
          margin-top: 32px;
        }
        @include respond-down(M){
          margin-top: 38px;
        }
        @include respond-down(S){
          margin-top: 30px;
        }
        @include respond-down(XS){
          margin-top: 26px;
        }
      }
    }
  }

  .article.results{
    @include respond-property(margin-top, 69px, 69px, 47px, 57px, 62px,  44px);
  }

  .sidebar{
    //position: -webkit-sticky;
    //position: sticky;
    //top: 40px;
    @include respond-up(L){
      position: absolute;
      top: 30px;
      right: 0;
    }

    transition: opacity 0.15s ease;
    opacity: 1;
    z-index: 100;

    &.fix {
      position: fixed;
      z-index: 200;
    }

    padding-right: 30px;
    width: max-content;

    @include respond-down(L){
      padding-right: 0;
      margin-right: -15px;
    }

    @include respond-down(M){
      margin-right: 0;
      width: 100%;
    }

    &__title{
      font-size: 20px;
      color: $col-active;
      padding-left: 15px;
      @include respond-property(margin-bottom, 21px, 21px, 6px, 1px, 1px, 0);

      @include respond-down(M){
        width: 100%;
        margin-top: 43px;
        padding-left: 5.5%;
      }
      @include respond-down(S){
        font-size: 18px;
      }
      @include respond-down(XS){
        margin-top: 36px;
      }
    }
  }
  .sidebar-content{
    position: relative;
  }
}

.sign-up{
  @include respond-property(margin-top, 40px, 40px, 23px, 40px, 31px, 37px);
  text-align: center;
  max-width: 189px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;

  &:hover{
    box-shadow: 0 11px 23px rgba(155,126,178,0.8);
    background: #9B7EB2;
    color: #fff;
  }
}

.card-doctor{
  width: max-content;
  position: relative;
  box-shadow: 0 0 15px #D0D2DF;
  border-radius: 6px;
  background: #FFF;
  @include respond-property(padding-top, 17px, 17px, 17px, 15px, 18px, 15px);
  @include respond-property(padding-bottom, 51px, 51px, 51px, 18px, 17px, 19px);
  @include respond-property(padding-left, 14px, 14px, 14px, 14px, 17px, 19px);
  @include respond-property(padding-right, 16px, 16px, 16px, 18px, 17px, 19px);

  @include respond-down(M){
    width: 100%;
  }

  &__img{
    width: 173px;
    // height: 231px;
    min-height: 100%;
    flex-shrink: 0;
    border-radius: 6px;
    overflow: hidden;

    @include respond-down(XS){
      width: 98px;
      height: 131px;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__more{
    position: absolute;
    bottom: 11px;
    right: 21px;
    display: none;
    font-size: 14px;
    font-weight: 500;
    color: $col-main;
    svg{
      transform: rotate(-90deg);
      fill: $col-main;
      width: 11.2px;
      height: 7.1px;
    }
    @include respond-down(M){
      display: inline;
    }
    @include respond-down(S){
      bottom: 17px;
      right: 17px;
    }
    @include respond-down(XS){
      bottom: 26px;
      right: auto;
      left: 132px;
    }
  }
  .card-doctor-info{
    margin-left: 18px;
    width: 221px;

    @include respond-down(M){
      margin-left: 21px;
      width: 100%;
    }
    @include respond-down(XS){
      margin-left: 14px;
    }
    &__title{
      color: $col-main;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      margin-top: 14px;
      line-height: 1;
      @include respond-down(M){
        margin-top: 0;
      }
      @include respond-down(S){
        margin-top: 14px;
      }

      &:first-child{

      }
    }
    &__header{
      display: flex;
      justify-content: space-between;
    }
    &__name{
      color: $col-bold;
      @include respond-property(font-size, 16px, 16px, 16px, 20px, 18px,  16px);
    }
    &__post{
      color: $col-bold;
      font-weight: 500;
      letter-spacing: 0.056em;
      padding-right: 14px;
      @include respond-property(font-size, 16px, 16px, 16px, 18px, 18px, 14px);
      @include respond-property(margin-top, 20px, 20px, 20px, 0, 14px, 12px);
      @include respond-property(margin-bottom, 28px, 28px, 28px, 29px, 24px, 22px);

      @include respond-down(M){
        padding-right: 21px;
      }
    }
    &__paragraph{
      color: $col-b0;
      font-size: 14px;
      line-height: 17px;
      margin-top: 6px;
      max-width: 280px;
      @include respond-down(M){
        margin-top: 9.6px;
      }
      @include respond-down(S){
        font-size: 12px;
        margin-top: 7.6px;
        max-width: 240px;
      }
    }
    &__icon{
      flex-shrink: 0;
      border-radius: 50%;
      border: 2px solid $col-main;
      width: 49px;
      height: 49px;
      display: flex;
      justify-content: center;align-items: center;

      @include respond-down(XS){
        width: 43px;
        height: 43px;
        position: absolute;
        bottom: 19px;
        right: 20px;
      }
      svg{
        width: 25px;
        height: 23px;

        @include respond-down(XS){
          width: 22px;
          height: 20px;
        }
      }
    }

    .card-doctor-info-inner{
      @include respond-down(M){
        display: flex;
      }
      @include respond-down(S){
        display: block;
      }
      @include respond-down(XS){
        display: none;
      }


    }
  }
  .card-doctor-main{
    display: flex;
    height: 100%;
  }


  .rating{
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
    width: 100%;

    @include respond-down(M){
      position: static;
      flex-direction: column;
      margin-top: 28px;
    }
    @include respond-down(S){
      display: none;
    }
  }
  .rating-assessments{
    display: flex;
    margin-left: 48px;

    @include respond-down(M){
      margin-left: 0;
    }
    .icon{
      color: $col-active;
      margin-right: 5px;
      &.filled{
        svg{
          fill: $col-active
        }
      }
      svg{
        width: 16px;
        height: 15px;
      }
    }
  }
  .rating-info{
    margin-right: 26px;
    color: $col-active;
    font-size: 12px;
    @include respond-down(M){
      margin-right: 0;
      line-height: 1;
      margin-top: 2px;
    }
  }
}

.list-type-dot {
  font-style: italic;

  .item {
    font-size: 16px;
    color: $col-b0;
    position: relative;
    padding-left: 26px;
    margin-bottom: 28px;

    @include respond-down(XL){
      padding-left: 23px;
    }
    @include respond-down(L){
      padding-left: 26px;
      margin-bottom: 18px;
    }
    @include respond-down(M){
      margin-bottom: 22px;
    }
    @include respond-down(XS){
      padding-left: 24px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &::before{
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      left: 0;top: 0;
      border: 2px solid $col-main;
      border-radius: 50%;
      width: 11px;
      height: 11px;

      @include respond-property(margin-top, 10px, 7px, 7px, 7px,  8px, 8px);

      @include respond-down(XS){
        width: 9px;
        height: 9px;
      }
    }
  }
}
