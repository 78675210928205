.contact-page {
  .contacts-wrapper {
    padding-top: 58px;
  }

  .map-box {
    top: 54px;
  }

  .how-to-full .subtitles {
    @include respond-down(M){
      text-align: center;
    }
  }

  .how-to-full .how-to-images {
    margin-right: 80px;

    @include respond-down(L){
      margin-right: 0;
    }

    .header-line {
      display: none;
    }

    .lines {
      @include respond-down(L){
        display: block;
      }

      .line {
        margin-bottom: 135px;
        align-items: flex-start;

        @include respond-down(L){
          display: flex;
          flex-direction: initial;
          flex: 0 0 100%;
        }
        @include respond-down(M){
          flex-direction: column;
          align-items: center;
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 80px;
          position: relative;
        }

        .item {
          flex: 0 0 46.5%;

          @include respond-down(M){
            flex: 0 0 auto;
            width: 100%;
          }

          &.image {
            flex: 0 0 590px;

            @include respond-down(L){
              flex: 0 0 50%;
              width: 50%;
            }

            @include respond-down(M){
              flex: 0 0 auto;
              width: 100%;
            }
          }

          .prefix {
            display: block;
            border-radius: 6px;
            background: #F4EFFA;
            padding: 29px 72px 20px 72px;
            margin-bottom: 50px;
            font-size: 16px;
            color: #414244;
            font-weight: 400;

            @include respond-down(L){
              padding: 16px 23px 10px 23px;
              margin-bottom: 24px;
              font-size: 14px;
            }

            p {
              margin-bottom: 0.3em;
            }

            .main {
              font-weight: 500;
              font-size: 18px;
              color: #583671;
              margin-bottom: 1em;

              @include respond-down(L){
                font-size: 16px;
              }

              b {
                font-weight: 800;
              }

              &:last-child {
                margin-bottom: 5px;
              }
            }
          }

          .postfix {
            @include respond-down(M){
              padding: 6px 23px;
            }
            @include respond-down(XS){
              padding: 0;
            }

            .bottom {
              display: initial;
              padding-top: 30px;

              .link {
                text-transform: uppercase;
                border-bottom: 1px solid #583671;
                color: #583671;
                font-size: 14px;
                letter-spacing: 0.05em;
                font-weight: 600;
              }
            }
          }


          .way {
            font-size: 25px;

            @include respond-down(L){
              font-size: 20px;
              margin-bottom: 20px;
            }

            .rounded {
              margin-right: 22px;
              box-shadow: 0 4px 15px rgba(187, 168, 125, 0.5);

              @include respond-down(L){
                display: flex;
                width: 44px;
                height: 44px;
              }

              @include respond-down(M){
                position: absolute;
                top: -20px;
                right: -42px;
              }
            }
          }

          .info {
            line-height: 1.65em;
            font-size: 16px;
            margin-left: 48px + 22px;

            @include respond-down(L){
              font-size: 14px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
