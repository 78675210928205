* {
  -webkit-tap-highlight-color: transparent;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font;
  font-size: 18px;
  line-height: 1.35em;
  min-height: 100vh;
  position: relative;
  background: #fff;

  &.pointer {
    cursor: pointer;
  }
}

a {
  text-decoration: none;
  color: #111;
}

em {
  font-style: italic;
}

strong {
  font-weight: bold;
}

.body {
  overflow: hidden;
}

.h1 {
  @include respond-property(font-size, 50px, 50px, 40px, 30px, 30px, 25px);
  font-family: $font-h;
  line-height: 1.2em;
  color: $col-bold;
  font-weight: bold;
}

.container {
  position: relative;
  max-width: 1400px;
  margin: auto;

  @include respond-down(L){
    margin: auto 5.5%;
  }

  &--margin-bottom{
    margin-bottom: 124px;
   
  }

  &--no-width{
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  &--no-width-M{
  
    @include respond-down(M){
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &--no-width-XS{
  
    @include respond-down(XS){
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
  &--width-XS{
    @include respond-down(XS){
      padding-left: 5.5%;
      padding-right: 5.5%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &--width-M{
    @include respond-down(M){
      padding-left: 5.5%;
      padding-right: 5.5%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.arrow {
  path {
    fill: $col-main;
  }
}

.page-header {
  padding-top: 40px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @include respond-down(L){
    padding-top: 34px;
    padding-bottom: 46px;
  }

  @include respond-down(M){
    padding-top: 5px;
    padding-bottom: 25px;
  }

  @include respond(XS){
    flex-wrap: wrap;
  }

  .h1 {
    @include respond(XS){
      margin-bottom: 16px;
    }
  }

  .selector-wrapper {
    .label {
      font-size: 14px;
      margin-bottom: 8px;
      letter-spacing: 0.01em;
      font-weight: 500;
      color: $col-main;
    }
    .selector {
      width: 256px;

      @include respond(XS){
        width: 100%;
      }
    }

    @include respond(XS){
      width: 100%;
    }
  }
}

.framed-photo {
  border-radius: 9px;
  padding: 9px;
  box-shadow: 0 0 21px #D0D2DF;
  flex-grow: 0;
  flex-shrink: 0;

  @include respond(XS){
    margin-bottom: 19px;
  }

  @include respond-down(L){
    border-radius: 7px;
    padding: 6px;
  }

  .photo {
    background: #eee no-repeat center center;
    background-size: cover;
    border-radius: 3px;
  }
}

.rounded {
  color: $col-bold;
  display: flex;
  align-items: center;
  margin-right: 36px;

  .arrow-back-svg {
    .figure {
      fill: $col-bold;
    }
  }

  .arrow-back {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 40px;
    display: flex;
    align-items: center;
    border: 2px solid transparent;
    transition: border 0.15s ease;
    justify-content: space-around;
  }

  .text {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.35em;
    letter-spacing: 0.060em;

    @include respond-down(L){
      font-size: 12px;
    }
  }

  @include respond-down(S){
    .arrow-back {
      border: 1px solid $col-bold;
    }
  }


  &:hover {
    .arrow-back {
      border: 2px solid $col-bold;
    }
  }
}

.scrolled {
  &::-webkit-scrollbar {
    width: 6px;  /* for vertical scrollbars */
    height: 6px; /* for horizontal scrollbars */
  }

  &::-webkit-scrollbar-track {
    background: #E3DEE7;
    border-radius: 10px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: $col-main;
    border-radius: 10px;
    cursor: pointer;
  }
}


.drop-down {
  position: relative;
  box-shadow: 0 5px 12px #D4D6EB;
  border-radius: 6px;

  &.disabled {
    box-shadow: 0 5px 12px transparent;
    opacity: 0.7;
    cursor: default !important;
  }

  .arrow {
    position: absolute;
    z-index: 30;
    right: 24px;
    top: 24px;
    transition: transform 0.25s ease;

    @include respond-down(L){
      top: 22px;
    }
    @include respond-down(S){
      right: 14px;
      top: 23px;
    }
    @include respond(XS){
      top: 20px;
    }

    .figure {
      fill: $col-bold
    }
  }

  .item {
    user-select: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: $col-bold;

    height: 50px;
    min-width: 360px;
    padding: 10px 54px 10px 24px;
    transition: background 0.15s ease;
    cursor: pointer;
    border-radius: 6px;

    @include respond-down(L){
      height: 46px;
    }
    @include respond-down(S){
      height: 50px;
      font-size: 14px;
      min-width: auto;
    }
    @include respond(XS){
      width: 100%;
      min-width: 0;
      height: 44px;
      padding: 10px 14px;
    }

    svg {
      width: 26px;
      margin: 0 10px 0 0;
      max-height: 26px;
    }
  }

  .drop-down-current {
    transition: border-radius 0.35s linear;
    position: relative;
    z-index: 25;
  }

  .drop-down-list {
    background: #fff;
    z-index: 20;
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    transition: max-height 0.25s ease, box-shadow 0.35s linear;

    padding-top: 50px;
    max-height: 50px;
    border-radius: 6px;

    @include respond-down(L){
      padding-top: 46px;
      max-height: 46px;
    }

    @include respond-down(S){
      padding-top: 50px;
      max-height: 50px;
    }

    @include respond(XS){
      padding-top: 44px;
      max-height: 44px;

      &.to-top {
        bottom: 0;
        top: auto;
        padding-top: 0px;
        padding-bottom: 0;
        margin-bottom: 48px;
        max-height: 0px;
        border-radius: 6px;
      }
    }

    .item {
      font-weight: 400;
      color: $col-b0;

      @include respond-up(L) {
        &:hover {
          background: #F4EFFA;
        }
      }

    }
  }

  &.opened {
    z-index: 50;

    .arrow {
      transform: rotate(180deg);
    }

    .drop-down-current {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .drop-down-list {
      max-height: 500px;
      box-shadow: 0 5px 12px #D4D6EB;

      @include respond-down(L){
        max-height: 460px;
      }
    }
  }

  &.opened-forever {
    .drop-down-list {
      position: relative;
      padding-top: 0;
      max-height: 400px;

      .item {
        border-radius: 0;
      }

      .item.selected {
        background: $col-main;
        color: #fff;
      }
    }
  }
}

.desktop-only {
  display: block;

  @include respond-down(M){
    display: none;
  }
}

.mobile-navigator {
  display: none;

  @include respond-down(M){
    display: block;
  }

  .filter {
    justify-content: flex-end;

    @include respond(XS){
      justify-content: space-between;
    }
  }

  .drop-down.opened {
    .arrow {
      transform: rotate(0);
    }
  }

  .ctg-selector {
    display: flex;
    padding: 16px 0;

    @include respond(XS){
      flex-wrap: wrap;
    }

    .full {
      width: 100%;

      .direction {
        .drop-down-list {
          //border-radius: 0;
          .item {
            .arrow {
              transition: transform 0.35s ease;
              .figure {
                fill: #583671;
              }
            }

            &.selected {
              .arrow {
                transform: rotate(180deg);
                .figure {
                  fill: #FFF;
                }
              }
            }
          }
        }
      }

      .direction-content {
        padding: 8px;

        .scroll-area {
          padding-right: 10px;
          padding-left: 12px;
        }
      }
    }

    .mb-5 {
      margin-bottom: 20px;
    }

    .direction {
      flex: 1 1 50%;
      // flex: 1 1 40%;
      z-index: 40;

      @include respond(XS){
        margin-bottom: 10px;
        flex: 0 0 100%;
      }

      .arrow .figure {
        fill: #fff
      }

      .drop-down-current {
        border-radius: 6px 0 0 6px;
        background: $col-main;

        @include respond(XS){
          border-radius: 6px;
        }

        .item {
          color: #fff;
        }
      }
    }

    .type {
      // flex: 1 1 60%;
      flex: 1 1 50%;
      display: none;

      &.selected {
        display: block;
      }

      .small-wrapper {
        padding: 20px 0;
      }

      .item.small {
        font-size: 12px;
        height: 30px;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background: #D6C4EC;
          border-radius: 6px;
          margin: 0 15px 0 0 ;
        }

        &.selected {
          &:before {
            background: $col-main;
          }
        }
      }

      .drop-down-current {
        border-radius: 0 6px 6px 0;
        background: $col-thin;

        @include respond(XS){
          border-radius: 6px;
        }

        .item {
          color: $col-bold;
        }
      }
    }
  }
}

.icon-green{
  box-shadow: 0 4px 15px rgba(187, 168, 125, 0.52);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $col-active;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-property(width, 69px, 63px, 58px, 58px, 58px, 58px);
  @include respond-property(height, 69px, 63px, 58px, 58px, 58px, 58px); 
  svg{
    fill: black;
    width: 42px;
    height: 42px;
    @include respond-down(XL){
      width: 39px;
      height: 39px;
    }
    @include respond-down(XL){
      width: 39px;
      height: 39px;
    }
    @include respond-down(L){
      width: 35px;
      height: 35px;
    }
 }
}

.breadcrumbs{
  display: flex;
  align-items:center;

  
  .breadcrumb-item{
    position: relative;
    margin-right: 26px;
    flex-shrink: 0;
    @include respond-down(M){
      font-size: 12px;
    }
    // @include respond-down(M){
    //   font-size: 12px;
    // }

    &:last-child{
      flex-shrink: 1;
      margin-right: 0;
      height: 24px;

      .text{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    &.active{
      .text{
        color: $col-active;
      }
    }

    .text{
      transition: all 0.25s;
      font-size: 14px;

      @include respond-down(M){
        font-size: 12px;
      }

      &:not(:last-child) {
        &:hover{
          color: $col-active;
        }
      }
    }
    &:last-child .breadcrumbs-icon {
      display:none;
    }
    .breadcrumbs-icon{
      top: 10px;
      width: 10px;
      display: block;
      position: absolute;
      // left: calc(100%+10px);
      right: -18px;
      transform: rotate(270deg);

      @include respond-down(M){
        width: 8px;
        right: -16px;
      }
    }
  } 


}