.closed-tab {
  background: linear-gradient(to right, #CFB986 0%, #B8A579 100%);
  color: #FFFFFF;
  position: relative;
  transition: margin-top 0.35s ease;

  &.hidden {
    @include respond-property(margin-top, -100px, -86px, -78px, -120px, -108px, -204px);
  }

  .closer {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
    cursor: pointer;
    padding: 10px;
    width: 30px;
    height: 30px;
    display: block;

    @include respond-down(M){
      top: 10px;
      margin: 0;
    }
    @include respond-down(XS){
      top: 0;
      right: 0;
      margin: 0;
    }

    svg {
      width: 10px;
      height: 10px;
      display: block;
    }
  }

  .we-open-wrapper {
    @include respond-property(height, 100px, 86px, 78px, 120px, 108px, 204px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;

    @include respond-down(XS){
      flex-direction: column;
      justify-content: space-around;
    }

    .we-open {
      display: flex;
      align-items: center;

      @include respond-down(M){
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;
      }
      @include respond-down(XS){
        align-items: center;
      }

      .in-circle {
        border-radius: 50px;
        width: 56px;
        height: 56px;
        border: 2px solid #FFF;
        align-items: center;
        display: flex;
        justify-content: space-around;

        @include respond-down(L){
          width: 40px;
          height: 40px;
        }
        @include respond-down(M){
          display: none;
        }

        svg {
          width: 34px;
          height: 34px;

          @include respond-down(L){
            width: 28px;
            height: 28px;
          }

          path {
            fill: #FFF;
            stroke: #FFF;
          }
        }
      }

      .alert {
        padding-left: 25px;
        font-size: 22px;

        @include respond-down(XL){
          font-size: 18px;
        }
        @include respond-down(L){
          font-size: 16px;
          padding-left: 18px;
        }
        @include respond-down(M){
          padding-bottom: 10px;
        }
        @include respond-down(S){
          font-size: 14px;
        }
        @include respond-down(XS){
          padding-left: 0;
        }
      }

      .timer {
        margin-left: 20px;
        display: flex;

        @include respond-down(XS){
          margin-left: 0;
        }

        .separator {
          padding: 8px 14px;
          font-size: 22px;
          transition: opacity 0.15s ease;

          @include respond-down(L){
            padding: 8px 8px;
          }
        }

        .framed {
          margin-bottom: 16px;
          border: 3px solid #FFF;
          border-radius: 6px;
          width: 64px;
          height: 44px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 22px;
          font-weight: bold;

          @include respond-down(XL){
            width: 54px;
            height: 40px;
          }
          @include respond-down(L){
            font-size: 20px;
            width: 50px;
            height: 40px;
          }

          .help {
            position: absolute;
            bottom: -25px;
            width: 130%;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            left: -15%;
            right: -15%;
          }
        }
      }
    }

    .call-me-open {
      display: flex;
      align-items: center;

      @include respond-down(M){
        flex-direction: column;
        align-items: flex-start;
        white-space: nowrap;
        margin-right: 50px;
      }
      @include respond-down(XS){
        align-items: center;
        margin-right: 0;
      }

      .text {
        margin-right: 32px;

        @include respond-down(XL){
          font-size: 18px;
        }
        @include respond-down(L){
          font-size: 16px;
        }
        @include respond-down(M){
          margin-bottom: 14px;
        }
        @include respond-down(S){
          font-size: 14px;
        }
        @include respond-down(XS){
          margin-right: 0;
        }
      }

      .button {
        background: #FFF;
        color: $col-active;

        @include respond-down(M){
          margin-bottom: 10px;
        }

        svg path {
          stroke: $col-active !important;
          fill: $col-active !important;
        }
      }
    }
  }
}

