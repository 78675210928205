$max-row-width: 7000;
$grid-columns: 12;

$XL: 1980;
$L: 1440;
$M: 1024;
$S: 768;
$XS: 576;


$grid: (
  XXL: (to: $max-row-width, from: $XL, gutter: 12),
  XL: (to: $XL, from: $L,  gutter: 12),
  L:  (to: $L,  from: $M,  gutter: 12),
  M:  (to: $M,  from: $S,  gutter: 12),
  S:  (to: $S,  from: $XS, gutter: 12),
  XS: (to: $XS, from: 0,   gutter: 0)
);

$white:  #fff;
// $col-header-bg:#8b6ea5;
$col-bg-item: rgba(130, 104, 157, 0.38);
$col-line:#B6A4C6;
$col-thin: #F4EFFA;
$col-main: #9B7EB2;
$col-bold: #583671;
$col-shadow-news-item: rgba(208, 210, 223, 0.55);
$col-shadow-news-item-active: rgba(161, 135, 193, 0.55);
$col-active: #CFB986;

$col-b0: #414244;
$col-b1: #757575;
$col-b2: #A1A1A1;

$font: "Ubuntu";
$font-h: "Cambria";

:export { L: $L; M: $M; S: $S; XS: $XS; }
