
.call-me {
  position: fixed;
  z-index: 1500;
  left: 0;
  right: 0;
  transition: opacity 0.35s ease, transform 0.35s ease;
  transform: scale(1.3);
  opacity: 0;
  overflow: auto;
  background: rgba(130, 104, 157, 0.38);
  min-height: 100vh;
  top: 0;
  bottom: 0;
  display: none;
  //padding-bottom: 100px;
  align-items: center;
  justify-content: space-around;

  &.visible {
    //display: flex;
    display: block;

    @include respond-down(XS){
      display: block;
    }
  }

  .call-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1450;
  }

  .call-window {
    border-radius: 6px;
    z-index: 1490;
    width: 560px;
    position: relative;
    background: #fff;
    box-shadow: 0 5px 25px #665482;
    padding: 30px 40px;
    margin: 100px auto;

    @include respond-down(L){
      width: 400px;
    }

    @include respond-down(S){
      width: 290px;
    }

    @include respond(XS){
      width: 90%;
      margin: 50px auto;
      height: auto;
    }

    .result {
      position: absolute;
      background: #fff;
      opacity: 0;
      transform: scale(0.7);
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.45em;
      transition: opacity 0.25s ease, transform 0.35s ease;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      z-index: 10;
      text-align: center;
      color: $col-main;

      &.visible {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.call-large {
      width: 730px;

      @include respond-down(L){
        width: 580px;
      }
      @include respond-down(M){
        width: 570px;
      }
      @include respond-down(S){
        width: 90%;
        margin: 50px auto;
      }
    }

    .head {
      display: flex;
      margin-bottom: 45px;
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond-down(L){
        margin-bottom: 30px;
      }
      @include respond-down(S){
        margin-bottom: 26px;
      }

      .logo-wrapper {
        width: 100%;
        text-align: center;

        @include respond-down(L){
          border-bottom: 1px solid rgba(112, 112, 112, 0.17);
          text-align: left;
          margin-bottom: 16px;
        }
        @include respond-down(S){
          margin: -10px -20px 10px -20px;
          padding-bottom: 14px;
        }

        .round-logo {
          @include respond-down(S){
            height: 54px;
            margin: 0;
          }
        }
      }

      .round-logo {
        flex: 0 0 100%;
        width: 90px;
        height: 90px;
        margin-bottom: 25px;

        @include respond-down(L){
          width: 74px;
          height: 74px;
          margin-bottom: 15px;
        }

        @include respond-down(S){
          height: 74px;
          margin: 0;
        }

        .figure {
          fill: $col-active;
        }
      }

      &.inline-head {
        justify-content: flex-start;
        align-items: center;

        .round-logo {
          flex: 0 0 auto;
          margin-bottom: 0;

          @include respond-down(XS){
            flex: 0 0 100%;
          }
        }

        .main {
          flex: 0 0 auto;
          margin-left: 28px;

          @include respond-down(XS){
            flex: 0 0 100%;
            margin-left: 0;
          }
        }
      }

      .main {
        flex: 1 1 90%;
        font-family: "Cambria";
        font-size: 30px;
        font-weight: bold;
        line-height: 1.25em;
        color: $col-bold;
        text-align: center;

        @include respond-down(L){
          font-size: 25px;
          padding-top: 10px;
        }
        @include respond-down(XS){
          font-size: 16px;
        }
        @include respond-down(S){
          font-size: 20px;
          flex: 0 0 100%;
        }
      }

      .menu-opener {
        display: flex;
        position: absolute;
        right: 20px;
        top: 20px;

        @include respond-down(M){
          width: 40px;
          height: 40px;
        }
      }
    }

    .record-to {
      margin-top: -25px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond-down(L){
        position: absolute;
        left: 140px;
        top: 30px;
        height: 74px;
        align-items: flex-start;
        justify-content: center;
        margin-top: 0;
      }
      @include respond-down(S){
        height: 54px;
        top: 20px;
        left: 100px;
        right: 70px;
        padding-right: 50px;
      }

      .line {
        width: 42px;
        height: 2px;
        background: $col-active;
        margin-bottom: 14px;

        @include respond-down(L){
          display: none;
        }
      }

      .work-title {
        margin-bottom: 4px;
        font-size: 18px;
        font-weight: bold;
        color: $col-bold;
        line-height: 1.35em;

        @include respond-down(L){
          font-size: 14px;
        }
      }

      .work-time {
        margin-bottom: 30px;
        line-height: 1.45em;
        font-size: 16px;
        color: $col-active;
        font-weight: 500;

        @include respond-down(L){
          font-size: 14px;
          display: block;
          white-space: nowrap;
          margin-bottom: 0;
        }
      }


    }
  }
}

.inputs {
  display: flex;
  margin-bottom: 15px;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  @include respond-down(L){
    margin-bottom: 10px;
  }
  @include respond-down(S){
    margin-bottom: 4px;
  }
  @include respond(XS){
    margin-bottom: 10px;
  }

  .form-control {
    width: 310px;
    font-size: 18px;
    padding: 0 27px;
    height: 56px;
    margin-bottom: 22px;

    @include respond-down(L){
      width: 250px;
      font-size: 14px;
      padding: 0 20px;
      height: 46px;
      margin-bottom: 17px;
    }
    @include respond-down(M){
      height: 40px;
    }
    @include respond-down(S){
      margin-bottom: 8px;
      width: 188px;
    }
    @include respond-down(XS){
      margin-bottom: 18px;
      width: 100%;
    }
  }
}

.submits {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;

  @include respond-down(S){
  }

  .conditions {
    font-size: 14px;
    flex: 0 0 360px;
    color: $col-b1;
    line-height: 1.35em;
    text-align: center;
    order: 2;

    @include respond-down(L){
      font-size: 12px;
      flex: 0 0 340px;
    }

    @include respond-down(M){
      font-size: 10px;
      line-height: 1.55em;
      flex: 0 0 300px;
    }

    @include respond-down(S){
      flex: 0 0 250px;
    }

    br {
      @include respond-down(M){
        display: none;
      }
    }

    a {
      text-decoration: underline;
      color: $col-main;
    }
  }

  .button {
    white-space: nowrap;
    background: $col-main;
    color: #fff;
    position: relative;
    overflow: hidden;
    width: 220px;
    order: 1;
    margin-bottom: 26px;

    @include respond-down(L){
      width: 206px;
    }
    @include respond-down(S){
      width: 180px;
      height: 38px;
      padding-left: 5px;
      padding-top: 0;
      padding-bottom: 0;
    }
    @include respond-down(XS){
      margin-bottom: 18px;
    }

    .default {
      position: relative;
      display: block;
      opacity: 1;
      transition: transform .25s ease, opacity .25s ease;
      padding-left: 40px;
      padding-right: 10px;
      margin-left: -8px;

      &.button-record {
        padding-left: 50px;
      }
    }

    .success {
      margin-left: -8px;
      position: absolute;
      display: block;
      padding-left: 40px;
      padding-right: 10px;
      transition: transform .25s ease, opacity .25s ease;
      transform: translateY(-30px);
      opacity: 0;

      &.button-record {
        padding-left: 50px;

        svg {
          left: 20px;
        }
      }
    }

    &.done {
      background: $col-active;
      border-color: $col-active;

      &:hover {
        cursor: default;
        box-shadow: none;
      }

      .default {
        opacity: 0.1;
        transform: translateY(30px);
      }

      .success {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.how-to-images {
  .prefix {
    display: none;
  }

  .postfix {
    .bottom {
      display: none;
    }
  }

  .lines {
    @include respond-down(L){
      display: flex;
      justify-content: space-between;
    }
    @include respond(XS){
      flex-wrap: wrap;
    }

    .line {
      @include respond-down(L){
        flex-direction: column;
        flex: 0 0 48%;
      }

      @include respond(XS){
        flex: 0 0 100%;
        flex-wrap: wrap;
        flex-direction: initial;
      }

      .item {
        @include respond-down(L){
          flex: 0 0 100%;
        }

        &.image {
          @include respond-down(L){
            width: 100%;
            margin-bottom: 18px;
          }
        }
      }
    }
  }

  .line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    align-items: center;

    @include respond(XS){
      flex-wrap: wrap;
    }

    .item {
      flex: 0 0 49%;
      font-size: 14px;
      color: #414244;
      line-height: 1.65em;

      @include respond-down(L){
        flex: 0 0 48%;
        font-size: 12px;
      }
      @include respond(XS){
        flex: 0 0 100%;
      }

      &.attention {
        background: #9B7EB2;
        border-radius: 6px;
        color: #FFF;
        font-size: 16px;
        padding: 13px 26px;
        flex: 0 0 45%;

        @include respond-down(L){
          font-size: 12px;
          flex: 0 0 48%;
        }
        @include respond-down(S){
          font-size: 10px;
        }
        @include respond(XS){
          flex: 0 0 100%;
          text-align: center;
          margin-bottom: 20px;
        }
      }

      &.image {
        box-shadow: 0 0 21px #D0D2DF;
        border-radius: 6px;
        width: 45%;
        flex: 0 0 45%;

        @include respond(XS){
          width: 100%;
          flex: 0 0 100%;
        }
      }

      &.attention-text {
        color: #414244;
        font-size: 16px;

        @include respond-down(L){
          font-size: 12px;
        }
        @include respond-down(S){
          font-size: 10px;
        }
        @include respond(XS){
          flex: 0 0 100%;
          font-size: 14px;
          text-align: center;
        }

        strong {
          font-weight: bold;
          color: #583671;
        }
      }
    }
  }
}

.way {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: $col-active;
  margin-bottom: 20px;

  @include respond-down(L){
    margin-bottom: 10px;
  }

  .rounded {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background: $col-active;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #FFF;
    font-size: 20px;
    font-weight: bold;
    margin-right: 12px;

    @include respond-down(L){
      display: none;
    }
  }
}


.call-me.we-closed {
  .call-background {
    background: rgba(155, 126, 178, 0.38);
  }
  .call-window {
    @include respond-up(S){
      width: 550px;
    }
  }

  .pre-title {
    text-transform: uppercase;
    font-weight: bold;
    color: #CFB986;
    @include respond-property('font-size', 20px, 18px, 14px, 14px, 12px, 12px);
  }

  .title {
    @include respond-property('font-size', 30px, 28px, 20px, 20px, 16px, 16px);
    color: #583671;
    line-height: 1.2em;
    font-weight: 500;
  }

  .long-line {
    height: 2px;
    margin: 15px 0;
    background: #CFB986;
  }

  .text {
    font-size: 16px;
    color: #414244;
    margin-bottom: 40px;

    @include respond-down(L){
      font-size: 14px;
    }
  }

  .center-btn {
    text-align: center;
    padding-bottom: 30px;

    .button {
      display: inline-block;
    }
  }

  .sign {
    display: flex;
    align-items: center;

    @include respond-down(XS){
      flex-direction: column;
    }

    .image {
      padding-left: 38px;
      position: relative;
      flex: 0 0 auto;
      margin-right: 35px;

      svg {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 0;
        top: 0;

        path {
          fill: #CFB986;
        }

        @include respond-down(L){
          width: 46px;
          height: 46px;
        }

      }

      .img {
        width: 118px;

        @include respond-down(L){
          width: 90px;
        }
      }
    }

    .sign-text {
      @include respond-property('font-size', 16px, 16px, 14px, 14px, 12px, 12px);
      color: #583671;
      line-height: 1.45em ;

      @include respond-down(XS){
        padding-top: 20px;
        max-width: 240px;
        text-align: center;
      }
    }
  }
}


@mixin modal($name) {
  body[data-modal="#{$name}"] {
    overflow: hidden;

    [data-menu-opener] {
      display: none;
    }

    @include respond-down(XS){
      position: fixed;
    }
  }

  [data-modal="#{$name}"] {
    overflow: hidden;

    &.call-me {
      overflow: auto;
    }

    [data-modal="#{$name}"] {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@include modal("online-go");
@include modal("reverse-call");
@include modal("write-box");
@include modal("sale-box");
@include modal("how-to-find");
@include modal("custom-modal");
