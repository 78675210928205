.online-start {

  & > .container {
    @include respond-down(S){
      //margin: auto 0;
    }
  }
  .bordered {
    margin: 120px 80px 100px 80px;
    border: 2px solid #CFB986;
    border-radius: 50px;
    background: #FFF;

    @include respond-down(XL){
      margin: 100px 60px 80px 60px;
    }
    @include respond-down(L){
      margin: 100px 0 80px 0;
    }
    @include respond-down(S){
      border: none;
      background: none;
    }
    @include respond-down(XS){
      margin: 30px 0 0 0;
    }

    .title-wrapper {
      height: 40px;
      margin: -21px auto 30px auto;
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-down(XS){
        margin: 0;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
      }


      .title {
        background: #FFF;
        padding: 0 10px;
        font-size: 40px;
        color: #583671;
        line-height: 1.05em;
        font-weight: 500;
        
        @include respond-down(L){
          font-size: 40px;
        }
        @include respond-down(XS){
          padding: 0;
          margin-bottom: 16px;

        }

      }
    }

    .sub-title {
      color: #583671;
      font-size: 25px;
      text-align: center;
      margin-bottom: 70px;

      @include respond-down(L){
        font-size: 20px;
        margin-bottom: 60px;
      }
      @include respond-down(XS){
        text-align: left;
        margin-bottom: 40px;
      }
    }

    .options {
      display: flex;
      width: 900px;
      margin: 0 auto;
      position: relative;

      @include respond-down(L){
        width: 850px;
      }
      @include respond-down(M){
        width: 710px;
      }
      @include respond-down(S){
        width: 410px;
        flex-direction: column;
      }
      @include respond-down(XS){
        width: auto;
      }

      &:before {
        content: '';
        height: 1px;
        background: #CFB986;
        position: absolute;
        left: 150px;
        width: 600px;
        top: 9px;

        @include respond-down(L){
          width: 560px;
          top: 8px;
        }
        @include respond-down(M){
          width: 476px;
          left: 120px;
        }
        @include respond-down(S){
          display: none;
        }
      }

      .option {
        color: #414244;
        font-size: 16px;
        width: 240px;
        margin: 0 30px;
        text-align: center ;

        @include respond-down(L){
          font-size: 14px;
        }
        @include respond-down(M){
        }
        @include respond-down(S){
          font-size: 16px;
          width: auto;
          margin: 0 0 30px 0;
          text-align: left;
          display: flex;
          align-items: flex-start;
        }

        .bullet {
          width: 18px;
          height: 18px;
          border-radius: 18px;
          background: #CFB986;
          box-shadow: 0 4px 15px rgba(187, 168, 125, 0.52);
          margin: 0 auto 40px auto;
          position: relative;

          @include respond-down(L){
            width: 16px;
            height: 16px;
          }
          @include respond-down(S){
            flex: 0 0 auto;
            margin: 7px 30px;
            width: 12px;
            height: 12px;
          }
          @include respond-down(XS){
            margin: 7px 35px 0 24px;
          }

          &.x:after,
          &.r:before {
            content: '';
            border-radius: 152px;
            border: 1px solid #CFB986;
            position: absolute;
            width: 52px;
            height: 52px;
            left: -18px;
            top: -18px;
            transition: opacity 0.35s ease, scale 0.35s ease;

            @include respond-down(L){
              width: 48px;
              height: 48px;
              left: -17px;
              top: -17px;
            }
            @include respond-down(S){
              width: 36px;
              height: 36px;
              left: -13px;
              top: -13px;
            }
          }

          &.x:after {
            width: 76px;
            height: 76px;
            left: -30px;
            top: -30px;

            @include respond-down(L){
              width: 70px;
              height: 70px;
              left: -28px;
              top: -28px;
            }
            @include respond-down(S){
              width: 52px;
              height: 52px;
              left: -21px;
              top: -21px;
            }
          }
        }

        a {
          color: #CFB986;
          transition: color 0.25s ease;

          &:hover {
            color: #583671;
          }
        }

        .msg {
          width: 46px;
          margin: 0 -5px -5px -5px;
        }

        .txt {
          margin-bottom: 18px;

          @include respond-down(L){
            margin-bottom: 12px;
          }
          @include respond-down(S){
            br { display: none }
          }
        }
      }
    }

    .carts {
      border: none;
      width: 1050px;
      margin: 0 auto 50px auto;
      flex-direction: column;

      @include respond-down(L){
        width: 90%;
      }
      @include respond-down(M){
        width: auto;
        flex-direction: column;
        margin: 0 40px 40px 40px;
      }
      @include respond-down(S){
        margin: 0 -6.5% 0 -6.5%;
      }

      .pay-button {
        width: 310px;
        margin: auto;
        text-align: center;
        display: inline-block;

        @include respond-down(L){
          width: 190px;
        }
        @include respond-down(M){
          margin: 0;
        }
        @include respond-down(S){
          display: none;
        }

        &.button-mobile {
          display: none;

          @include respond-down(S){
            display: block;
            width: 280px;
            margin: 0 auto 20px auto;
          }
        }
      }

      .profile-cart {
        text-align: left;
        border-radius: 19px;
        box-shadow: 0 7px 19px rgba(0,0,0,0.2);

        @include respond-down(M){
          //flex: 0 0 410px;
          //width: 410px;
        }
        @include respond-down(S){
          border-radius: 0;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 0;
          background: #FFF;
          box-shadow: 0 1px 8px rgba(0,0,0,0.2);
        }

        .cart-wrapper {
          @include respond-down(S){
            margin-bottom: 4px;
          }
        }

        .photo {
          width: 128px;
          height: 128px;
          background-position: top center;
          margin-right: 18px;

          @include respond-down(S){
            width: 100px;
            height: 130px;
          }
          @include respond-down(XS){
            width: 90px;
            height: 120px;
            margin-right: 10px;
          }
        }

        .name-iconic {
          display: flex;
          align-items: center;

          @include respond-down(M){
            margin-bottom: 20px;
          }
          @include respond-down(S){
            margin-bottom: 2px;
          }
        }

        .online {
          color: #9B7EB2;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 0.056em;
          padding-right: 70px;
          font-weight: 400;
          line-height: 1.55em;
          padding-top: 12px;
          margin-bottom: 20px;

          @include respond-down(S){
            padding-top: 4px;
            margin-bottom: 4px;
          }
        }

        .name {
          font-size: 18px;
          padding-right: 0;
          color: #583671;
          font-weight: 500;
          margin-bottom: 4px;
          padding-top: 0;

          @include respond-down(XS){
            font-size: 16px;
            line-height: 1.3em;

          }
        }

        .position {
          color: #414244;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 0;

          @include respond-down(S){
            font-size: 14px;
            margin-bottom: 4px;
          }
        }

        .price {
          color: #583671;
          font-size: 25px;
          font-weight: 500;
          margin-bottom: 12px;
          text-align: center;

          @include respond-down(L){
            font-size: 20px;
          }
          @include respond-down(S){
            margin-bottom: 2px;
            font-size: 18px;
          }

          .sep {
            font-weight: 400;
            color: #9B7EB2;
          }

          .mini {
            color: #9B7EB2;
            font-size: 16px;
            font-weight: bold;

            @include respond-down(XS){
              font-size: 14px;
            }
          }
        }

        .symbol {
          position: initial;
          margin-right: 25px;

          @include respond-down(S){
            position: absolute;
            margin-right: 0px;
          }
          @include respond-down(XS){
            bottom: auto;
            top: 0px;
          }

        }

        .data {
          position: relative;
          overflow: visible;

          .action-part {
            position: absolute;
            right: 10px;
            top: 10px;
            bottom: 10px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            @include respond-down(M){
              position: initial;
              flex-direction: initial;
              justify-content: space-between;

            }

          }

        }

      }
    }

    .bottom-button {
      width: 180px;
      text-align: center;
      margin: 0 auto -25px auto;
      display: block;

      @include respond-down(S){
        display: none;
      }

      //bottom-button-req
    }

    .median-button {
      display: block;
      width: 180px;
      text-align: center;
      margin: -10px auto 50px auto;

      @include respond-up(M){
        display: none;
      }
    }
  }
}