.calendar-content {

  .button {
    padding: 13px 18px;

    @include respond-down(L){
      padding: 12px 18px;
    }
  }

  .drop-down {
    box-shadow: 0 5px 12px #D4D6EB;
    border-radius: 6px;

    .item {
      @include respond-down(L){
        min-width: 300px;
      }
      @include respond-down(M){
      }
    }

    &.disabled {
      box-shadow: none;

      .item {
        background: #e2e4f9;
        cursor: default;
      }
    }
  }

  .cal-row {
    display: flex;

    @include respond-down(M){
      flex-wrap: wrap;
    }

    &.lined {
      padding: 26px 0;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(112, 112, 112, 0.17);
      }
    }

    .first {
      flex: 0 0 25%;
      padding-right: 20px;
      position: relative;
      padding-left: 8px;
      display: flex;
      align-items: center;

      @include respond-down(M){
        flex: 0 0 100%;
        //margin-bottom: 25px;
      }

      svg {
        width: 30px;
        height: 30px;
        margin-right: 15px;
        //position: absolute;
        //left: 0;
        //top: 5px;
      }

      .sh-title {
        font-size: 18px;
        font-weight: bold;
        color: $col-bold;
        line-height: 1.45em;
        margin-bottom: 5px;

        @include respond-down(L){
          font-size: 16px;
        }
      }

      .sh-desc {
        color: $col-b0;
        font-size: 14px;
        line-height: 1.45em;

        @include respond(L){
          font-size: 12px;
        }
        @include respond-down(M){
          margin-bottom: 25px;
        }
        @include respond(XS){
          font-size: 12px;
        }
      }
    }

    .right {
      flex: 1 1 90%;
      display: flex;

      @include respond-down(M){
        flex-wrap: wrap;
      }

      .item {
        flex: 1 1 15%;
        text-align: center;

        @include respond-down(M){
          flex: 0 0 108px;
        }

        &.empty {
          @include respond-down(M){
            display: none;
          }
        }

        .item-content {
          box-shadow: 0 5px 12px #D4D6EB;
          border-radius: 6px;
          padding: 13px 18px;
          margin: 0 7px;
          text-align: left;
          background: #fff;
          transition: background 0.25s ease;
          user-select: none;

          @include respond(L){
            padding: 10px 8px;
          }
          @include respond-down(M){
            margin: 0 5px 10px 5px;
          }

          .ddt {
            text-transform: none;
            font-size: 11px;
            color: $col-bold;
            display: block;
          }

          .day-name {
            line-height: 1.35em;
            font-size: 12px;
            font-weight: bold;
            color: $col-active;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            transition: color 0.15s ease;
          }

          .work-time {
            line-height: 1.35em;
            font-size: 14px;
            color: $col-b0;
            transition: color 0.15s ease;
            white-space: nowrap;

            @include respond(L){
              font-size: 12px;
            }
          }
        }
      }

      &.cal-header {
        @include respond-down(M){
          display: none;
        }

        .item {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          color: $col-active;
          letter-spacing: 0.1em;

          @include respond-down(M){
            font-size: 10px;
          }
        }
      }
    }
  }

  .spacer-row {
    @include respond-down(M){
      height: 400px;
    }
    @include respond(XS){
      height: 0;
    }
  }

  .scroll-area-wrapper {
    position: relative;

    &:after, &:before {
      content: '';
      position: absolute;
      height: 20px;
      right: 6px;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
      z-index: 20;
    }

    &:before {
      right: 6px;
      bottom: auto;
      top: 0;
      background: linear-gradient(to top, rgba(255, 255, 255, 0), #fff);
    }
  }

  .scrolled {
    @include respond-down(M){
      margin-left: -14px;
      padding-left: 14px;
      padding-right: 14px;
      max-height: 600px;
      overflow: auto;
    }

    @include respond(XS){
      max-height: unset;
    }
  }

  .continue-block {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    margin-bottom: 50px;
    align-items: flex-end;

    @include respond-down(L){
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }
    @include respond-down(M){
      flex-direction: column;
    }
    @include respond-down(S){
    }

    &.selected {
      bottom: 0;

      .help-text {
        display: none;
      }

      .help-text-one {
        display: flex;
        flex-wrap: wrap;

        @include respond(XS){
          display: block;
        }
      }
    }

    .science-title {
      flex: 0 0 auto;
      margin-right: 100px;

      @include respond-down(L){
        margin-bottom: 20px;
        margin-right: 0;
        br {
          display: none;
        }
      }
    }

    .inputs-wrapper {
      align-items: center;
      display: flex;

      @include respond-down(M) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
      @include respond-down(S) {
        flex-direction: column;
      }

      .write-dropdown {
        margin-right: 20px;

        @include respond-down(M) {
          margin: 0 10px 20px 10px;
        }
      }

      .hidden-selectors {
        display: none;
      }
    }

    .button-wrapper {
      z-index: 10;
      position: relative;

      @include respond-down(M) {
        margin: 0 10px 20px 10px;
      }
      @include respond-down(XS) {
        margin-top: 40px;
      }

      &:after, &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
        box-shadow: 0 0 0 1px $col-main;
        border-radius: 4px;
        opacity: 0;
        background: rgba(155, 126, 178, 0.4);
      }

      &.accent {
        /*
        &:after {
          animation: wave 0.6s ease;
          animation-delay: 0.1s;
        }
        &:before {
          animation: wave 0.6s ease;
          animation-delay: 0.4s;
        }
        */
        .button {
          animation: blink 1.0s ease;
        }
      }

      @keyframes blink {
        0% {
          background: #fff;
          color: #9B7EB2;
        }
        20% {
          background: #9B7EB2;
          color: #fff;
        }
        40% {
          background: #fff;
          color: #9B7EB2;
        }
        60% {
          background: #9B7EB2;
          color: #fff;
        }
        80% {
          background: #fff;
          color: #9B7EB2;
        }
        100% {
          background: #9B7EB2;
          color: #fff;
        }
      }

      @keyframes wave {
        0% { opacity: 0; transform: scale(1); }
        5% { opacity: 1; transform: scale(1); }
        40% { opacity: 1; }
        100% { opacity: 0; transform: scale(1.9, 1.9); }
      }

      .button {
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        z-index: 15;
        background: #fff;

        @include respond(XS){
          padding-left: 20px;
          padding-right: 20px;
        }

        &:hover:not(.disabled) {
          background: $col-main;
        }
      }
    }
  }

  .or-by-phone {
    margin-bottom: 150px;
    text-align: center;
    color: #583671;

    .helper {
      font-size: 18px;
      margin-bottom: 0.5em;
    }

    .p-phone {
      font-size: 22px;
      color: #583671;
      font-weight: bold;
      padding: 4px;
      margin: -4px;
    }
  }

}