@font-face {
    font-family: "Cambria";
    src: url("Cambria.ttf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Cambria";
    src: url("Cambria-Bold.ttf");
    font-style: normal;
    font-weight: bold;
}
